import React, { FC, ReactNode, PointerEvent } from "react";
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/system";

import { COLORS, SPACINGS } from "../../common/consts";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { makeShouldForwardProps } from "../../common/utils/ui";
import Button, { ButtonProps } from "./Button";
import Text, { TextVariant } from "./Text";

interface DialogProps extends Omit<MuiDialogProps, "children" | "onClose"> {
  title?: string;
  children: ReactNode;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  onClose?: () => void;
}

const StyledDialog = styled(MuiDialog)`
  .MuiDialogContent-root {
    padding: ${SPACINGS.lg};
  }

  .MuiDialogActions-root {
    padding: ${SPACINGS.lg};
  }
`;

interface DialogHeaderProps {
  isTitleExist: boolean;
}
const shouldForwardProp = makeShouldForwardProps<DialogHeaderProps>([
  "isTitleExist",
]);
const DialogHeader = styled("div", {
  shouldForwardProp,
})<DialogHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isTitleExist }) =>
    isTitleExist ? "space-between" : "end"};
  padding: ${SPACINGS.sm};
`;

const Dialog: FC<DialogProps> = ({
  title,
  open,
  children,
  onClose,
  primaryButtonProps,
  secondaryButtonProps,
}) => {
  const handleClose = (
    _event: PointerEvent<HTMLDivElement>,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason && reason === "backdropClick") return;

    if (onClose) {
      onClose();
    }
  };
  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogHeader isTitleExist={!!title}>
        {title && <Text variant={TextVariant.md}>{title}</Text>}
        <CloseIcon
          width="18px"
          height="18px"
          onClick={onClose}
          className="cursor-pointer"
          color={COLORS.chartBouncesPrevented}
        />
      </DialogHeader>
      <DialogContent>{children}</DialogContent>
      <DialogActions className="d-flex align-items-center justify-content-center">
        {secondaryButtonProps && (
          <Button className="px-3" {...secondaryButtonProps} />
        )}
        {primaryButtonProps && (
          <Button className="px-3" {...primaryButtonProps} />
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default Dialog;
