import React, { FC } from "react";

import Text, { TextVariant } from "../../components/Display/Text";

const ContactAdmin: FC = () => {
  return (
    <div className="d-flex flex-column gap-3">
      <Text variant={TextVariant.sm}>
        In order to connect boring to Outreach you need admin privileges in
        Outreach.
      </Text>
      <Text variant={TextVariant.sm}>
        If you don&apos;t have admin access, please contact your Outreach admin
        to connect boring to Outreach for you.
      </Text>
    </div>
  );
};

export default ContactAdmin;
