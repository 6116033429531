import React, { FC } from "react";
import { styled } from "@mui/system";
import { SPACINGS } from "../../common/consts";

const StyledContainer = styled("div")`
  position: fixed;
  top: ${SPACINGS.xxl};
  right: ${SPACINGS.xl};
`;

const EnvLabel: FC<{ env: string }> = ({ env }) => {
  return (
    <StyledContainer>
      <div className="badge badge-brand">{env}</div>
    </StyledContainer>
  );
};

export default EnvLabel;
