import React, { FC, ReactNode, useState } from "react";
import { css, styled } from "@mui/system";
import {
  CartesianGrid,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  DefaultLegendContentProps,
} from "recharts";
import { COLORS, FONTS, StatusColors } from "../../common/consts";
import {
  EmailStatuses,
  ExtendedEmailStatuses,
  GeneralStatuses,
  OrganizationStats,
  OutreachEmailStatuses,
} from "../../types/organizations";
import { formatShortDate } from "../../common/utils/date-time";
import Button, { ButtonVariants } from "../../components/Display/Button";
import { TabSections } from "./ProspectsTabManager";
import ChartTooltip, {
  getColorByEntryName,
  getLabelByEntryName,
  getShapeByEntryName,
} from "./ChartTooltip";

const StyledContainer = styled("div")`
  width: 100%;
  height: 100%;
  min-width: 100px;

  .recharts-wrapper {
    margin-left: -2rem;
    margin-bottom: -3.5rem;
  }
`;

const StyledList = styled("div")`
  justify-content: flex-end;
  align-items: center;
`;

export interface BaseMainChartEntry {
  date: string;
  percentages: OrganizationStats;
  prospectsChecked?: number;
}
interface MainChartDataEntry {
  date: string;
  prospectsChecked: number;
  [OutreachEmailStatuses.Valid]: number;
  [OutreachEmailStatuses.Invalid]: number;
  [OutreachEmailStatuses.Questionable]: number;
  [OutreachEmailStatuses.NoEmail]: number;
  [ExtendedEmailStatuses.EnrichedByBoring]: number;
  [ExtendedEmailStatuses.BouncesPrevented]: number;
}

export interface MainChartDataAccuracy extends BaseMainChartEntry {
  [OutreachEmailStatuses.Valid]: number;
  [OutreachEmailStatuses.Invalid]: number;
  [OutreachEmailStatuses.Questionable]: number;
  [OutreachEmailStatuses.NoEmail]: number;
}

export interface MainChartBoringRoiData extends BaseMainChartEntry {
  [OutreachEmailStatuses.Invalid]: number;
  [ExtendedEmailStatuses.EnrichedByBoring]: number;
  [ExtendedEmailStatuses.BouncesPrevented]: number;
}

export type MainChartTabSpecificData = (
  | BaseMainChartEntry
  | MainChartDataAccuracy
  | MainChartBoringRoiData
)[];

export type MainChartData = MainChartDataEntry[];

interface Props {
  data: MainChartTabSpecificData;
  children?: ReactNode;
  selectedTab: TabSections;
}

interface LegendButtonWrapperProps {
  color: string;
}

export const LegendButtonWrapper = styled("div")<LegendButtonWrapperProps>`
  ${({ color }) => css`
    color: ${color};
  `};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
`;

const StyledButton = styled(Button)`
  &&.MuiButton-root {
    padding: 0.7rem;
    font: ${FONTS.sm};
    font-weight: 300;
  }

  .MuiButton-startIcon {
    margin-right: 0.2rem;
  }
`;

const renderLegend = (
  props: Omit<DefaultLegendContentProps, "onClick"> & {
    onClick: (value: EmailStatuses) => void;
    visibleLines: Record<EmailStatuses, boolean>;
    selectedTab: TabSections;
  }
) => {
  const { payload, onClick, selectedTab, visibleLines } = props;

  const legendsByTabs: Record<TabSections, EmailStatuses[]> = {
    [TabSections.ProspectsVerified]: [GeneralStatuses.ProspectsChecked],
    [TabSections.BoringRoi]: [
      ExtendedEmailStatuses.EnrichedByBoring,
      ExtendedEmailStatuses.BouncesPrevented,
    ],
    [TabSections.EmailDataAccuracy]: [
      OutreachEmailStatuses.Valid,
      OutreachEmailStatuses.Invalid,
      OutreachEmailStatuses.Questionable,
      OutreachEmailStatuses.NoEmail,
    ],
  };

  if (!payload) return;
  const legendsToRender = payload.filter((entry) =>
    legendsByTabs[selectedTab].includes(entry.value as EmailStatuses)
  );

  return (
    <StyledList className="w-100 d-flex">
      {legendsToRender?.map(({ value }, index) => {
        const ShapeComponent = getShapeByEntryName(value);
        const entryColor = getColorByEntryName(value);
        const status = value as EmailStatuses;

        return (
          <div key={`item-${index}`} onClick={() => onClick(status)}>
            <StyledButton
              key={`item-${index}`}
              variant={ButtonVariants.pill}
              active={visibleLines[status]}
              startIcon={
                <LegendButtonWrapper
                  color={
                    visibleLines[status]
                      ? COLORS.foregroundSecondaryLight
                      : entryColor
                  }
                >
                  <ShapeComponent />
                </LegendButtonWrapper>
              }
            >
              {getLabelByEntryName(value)}
            </StyledButton>
          </div>
        );
      })}
    </StyledList>
  );
};

const MainChart: FC<Props> = ({ data, selectedTab, children }) => {
  const [visibleLines, setVisibleLines] = useState<
    Record<EmailStatuses, boolean>
  >({
    prospectsChecked: false,
    [OutreachEmailStatuses.Valid]: false,
    [OutreachEmailStatuses.Invalid]: false,
    [OutreachEmailStatuses.Questionable]: false,
    [OutreachEmailStatuses.NoEmail]: false,
    [ExtendedEmailStatuses.EnrichedByBoring]: false,
    [ExtendedEmailStatuses.BouncesPrevented]: false,
  });

  const toggleLineVisibility = (key: EmailStatuses) => {
    setVisibleLines((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <StyledContainer>
      {children}
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid stroke="transparent" />
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={{
              stroke: COLORS.chartAxisColor,
              fill: COLORS.chartAxisColor,
              color: COLORS.chartAxisColor,
            }}
            tick={{
              fill: COLORS.chartAxisColor,
              fontSize: "0.8rem",
              fontWeight: 300,
              color: COLORS.chartAxisColor,
            }}
            tickFormatter={formatShortDate}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            includeHidden
            tickLine={false}
            axisLine={{
              stroke: COLORS.chartAxisColor,
              fill: COLORS.chartAxisColor,
              color: COLORS.chartAxisColor,
            }}
            tick={{
              fill: COLORS.chartAxisColor,
              fontSize: "0.8rem",
              fontWeight: 300,
              color: COLORS.chartAxisColor,
            }}
            padding={{ top: 5, bottom: 5 }}
          />
          <RechartsTooltip
            content={<ChartTooltip selectedTab={selectedTab} />}
          />
          <Legend
            verticalAlign="top"
            content={(props) =>
              renderLegend({
                ...props,
                onClick: toggleLineVisibility,
                visibleLines,
                selectedTab,
              })
            }
          />
          {selectedTab === TabSections.ProspectsVerified && (
            <Line
              type="linear"
              dataKey={GeneralStatuses.ProspectsChecked}
              stroke={StatusColors[GeneralStatuses.ProspectsChecked]}
              dot={false}
              isAnimationActive={true}
              strokeWidth={2}
              hide={visibleLines[GeneralStatuses.ProspectsChecked]}
            />
          )}
          <Line
            type="linear"
            dataKey={OutreachEmailStatuses.Valid}
            stroke={StatusColors[OutreachEmailStatuses.Valid]}
            dot={false}
            isAnimationActive={true}
            strokeWidth={2}
            hide={visibleLines[OutreachEmailStatuses.Valid]}
          />
          {selectedTab === TabSections.EmailDataAccuracy && (
            <Line
              type="linear"
              dataKey={OutreachEmailStatuses.Invalid}
              stroke={StatusColors[OutreachEmailStatuses.Invalid]}
              dot={false}
              isAnimationActive={true}
              strokeWidth={2}
              hide={visibleLines[OutreachEmailStatuses.Invalid]}
            />
          )}
          <Line
            type="linear"
            dataKey={OutreachEmailStatuses.Questionable}
            stroke={StatusColors[OutreachEmailStatuses.Questionable]}
            dot={false}
            isAnimationActive={true}
            strokeWidth={2}
            hide={visibleLines[OutreachEmailStatuses.Questionable]}
          />
          <Line
            type="linear"
            dataKey={OutreachEmailStatuses.NoEmail}
            stroke={StatusColors[OutreachEmailStatuses.NoEmail]}
            dot={false}
            isAnimationActive={true}
            strokeWidth={2}
            hide={visibleLines[OutreachEmailStatuses.NoEmail]}
          />
          <Line
            type="linear"
            dataKey={ExtendedEmailStatuses.BouncesPrevented}
            stroke={StatusColors[ExtendedEmailStatuses.BouncesPrevented]}
            dot={false}
            isAnimationActive={true}
            strokeWidth={2}
            hide={visibleLines[ExtendedEmailStatuses.BouncesPrevented]}
          />
          <Line
            type="linear"
            dataKey={ExtendedEmailStatuses.EnrichedByBoring}
            stroke={StatusColors[ExtendedEmailStatuses.EnrichedByBoring]}
            dot={false}
            isAnimationActive={true}
            strokeWidth={2}
            hide={visibleLines[ExtendedEmailStatuses.EnrichedByBoring]}
          />
        </LineChart>
      </ResponsiveContainer>
    </StyledContainer>
  );
};

export default MainChart;
