import React, { FC } from "react";
import { LinearProgress } from "@mui/material";
import { Box, styled } from "@mui/system";
import { COLORS, SPACINGS } from "../../common/consts";
import Text, { TextVariant } from "../../components/Display/Text";

export enum LoadingVariant {
  SPINNER = "spinner",
  BAR = "bar",
}

const StyledBox = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledLinearProgress = styled(LinearProgress)`
  width: 15rem;
  margin: ${SPACINGS.lg} 0;
  color: ${COLORS.foregroundBrand};

  .MuiLinearProgress-bar {
    color: ${COLORS.foregroundBrand};
    transition: none;
    opacity: 1;
  }
`;

const Loading: FC<{
  size?: "sm";
  variant?: LoadingVariant;
}> = ({ size = "sm", variant = LoadingVariant.SPINNER }) => {
  if (variant === LoadingVariant.BAR) {
    return (
      <StyledBox>
        <StyledLinearProgress color="inherit" />
        <Text variant={TextVariant.sm}>Loading...</Text>
      </StyledBox>
    );
  }

  return (
    <span
      className={`spinner-border spinner-border-${size}`}
      role="status"
      aria-hidden="true"
    />
  );
};

export default Loading;
