import React, { FC } from "react";
import { Controller } from "react-hook-form";

import {
  validateCustomFieldMaxValue,
  validateCustomFieldMinValue,
  validateUniqueCustomField,
} from "../../common/utils/form";
import Text, { TextVariant } from "../../components/Display/Text";
import NumberInput from "../../components/DataInput/NumberInput";
import { FieldControllerPropsBase } from "./Onboarding";

type JobChangeControllerProps = FieldControllerPropsBase & {
  emailStatusCustomFieldValue?: number | null;
  timestampCustomFieldValue?: number | null;
  enrichedCustomFieldValue?: number | null;
};

const JobChange: FC<JobChangeControllerProps> = ({
  control,
  errors,
  emailStatusCustomFieldValue,
  timestampCustomFieldValue,
  enrichedCustomFieldValue,
  setValue,
}) => {
  return (
    <div className="d-flex flex-column gap-4 align-items-center">
      <Text variant={TextVariant.sm} className="text-center">
      Choose a custom field for us to mark with the words &quot;Job change&quot; when an enriched prospect switched companies
      </Text>
      <div className="w-40">
        <Controller
          name="organizationSettings.jobChangeCustomField"
          control={control}
          rules={{
            validate: (value) =>
              validateUniqueCustomField({
                isEnabled: true,
                value: value?.toString() || "",
                otherValues: [
                  emailStatusCustomFieldValue?.toString() || "",
                  timestampCustomFieldValue?.toString() || "",
                  enrichedCustomFieldValue?.toString() || "",
                ],
              }),
            min: validateCustomFieldMinValue({ minValue: 1, isEnabled: true }),
            max: validateCustomFieldMaxValue({
              maxValue: 150,
              isEnabled: true,
            }),
          }}
          render={({ field: { ref, value } }) => {
            return (
              <NumberInput
                label="Job change"
                inputRef={ref}
                value={value}
                onChange={(value: number) => {
                  setValue("organizationSettings.jobChangeCustomField", value);
                }}
                error={!!errors.organizationSettings?.jobChangeCustomField}
                helperText={
                  errors.organizationSettings?.jobChangeCustomField?.message
                }
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default JobChange;
