import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { css, styled } from "@mui/system";
import MainChart, {
  BaseMainChartEntry,
  MainChartBoringRoiData,
  MainChartData,
  MainChartDataAccuracy,
  MainChartTabSpecificData,
} from "./MainChart";
import {
  ExtendedEmailStatuses,
  GeneralStatuses,
  OrganizationStats,
  OutreachEmailStatuses,
} from "../../types/organizations";
import {
  COLORS,
  FONT_WEIGHTS,
  FONTS,
  RADII,
  SHADOWS,
  SPACINGS,
  StatusColors,
} from "../../common/consts";
import { calculateStatsPercentages } from "../../common/utils/stats";
import { makeShouldForwardProps } from "../../common/utils/ui";
import Text, { TextVariant } from "../../components/Display/Text";
import Card from "../../components/Display/Card";
import Loading, { LoadingVariant } from "../../components/Display/Loading";

const StatCard = styled("div")`
  border-radius: ${RADII.md};
  min-width: 9rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TitleText = styled(Text)`
  color: ${COLORS.foregroundBrand};
  font: ${FONTS.sm};
  font-weight: ${FONT_WEIGHTS.medium};
  letter-spacing: 0.025rem;
`;

const SubtitleText = styled(Text)`
  color: ${COLORS.foregroundSecondary};
  font: ${FONTS.sm};
  font-weight: 275;
`;

const NoPaddingCard = styled(Card)`
  padding: ${SPACINGS.none};
`;

const ChartContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${COLORS.borderPrimary};
  overflow: hidden;
`;

const PaddingDiv = styled("div")`
  padding: ${SPACINGS.lg};
`;

const shouldForwardProp = makeShouldForwardProps<ProspectsTabSectionProps>([
  "active",
]);
const ProspectsTabSection = styled(
  ({ active, ...rest }: ProspectsTabSectionProps) => <PaddingDiv {...rest} />,
  { shouldForwardProp }
)`
  padding: ${SPACINGS.xl};
  border-bottom: 1px solid ${COLORS.borderPrimary};
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.backgroundBrandLightHover};
  }

  ${({ active }) =>
    active &&
    css`
      box-shadow: ${SHADOWS.inset} ${SHADOWS.brand};
      background-color: ${COLORS.backgroundBrandLight};
      color: ${COLORS.foregroundBrand};
    `}
`;

const NumberText = styled(Text)`
  margin: 0px;
  font-weight: 550;
  line-height: 1.5;
`;

interface ProspectTabManagerProps {
  globalStats: OrganizationStats;
  stats: MainChartData;
  isLoading?: boolean;
}

export enum TabSections {
  ProspectsVerified = "ProspectsVerified",
  EmailDataAccuracy = "EmailDataAccuracy",
  BoringRoi = "BoringRoi",
}

interface ProspectsTabSectionProps {
  className?: string;
  active?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children?: ReactNode;
}

const filterStatsByTab = (
  stats: MainChartData,
  selectedTab: TabSections
): MainChartTabSpecificData => {
  return stats.map((dailyStat) => {
    const percentages = calculateStatsPercentages({
      prospectsChecked: dailyStat.prospectsChecked,
      valid: dailyStat[OutreachEmailStatuses.Valid],
      invalid: dailyStat[OutreachEmailStatuses.Invalid],
      questionable: dailyStat[OutreachEmailStatuses.Questionable],
      noEmail: dailyStat[OutreachEmailStatuses.NoEmail],
      bouncesPrevented: dailyStat[ExtendedEmailStatuses.BouncesPrevented],
      enrichedByBoring: dailyStat[ExtendedEmailStatuses.EnrichedByBoring],
    });

    switch (selectedTab) {
      case TabSections.ProspectsVerified: {
        const prospectsChecked: BaseMainChartEntry = {
          percentages,
          date: dailyStat.date,
          [GeneralStatuses.ProspectsChecked]: dailyStat.prospectsChecked,
        };

        return prospectsChecked;
      }

      case TabSections.BoringRoi: {
        const boringRoi: MainChartBoringRoiData = {
          percentages,
          date: dailyStat.date,
          [OutreachEmailStatuses.Invalid]: dailyStat.invalid,
          [ExtendedEmailStatuses.EnrichedByBoring]: dailyStat.enrichedByBoring,
          [ExtendedEmailStatuses.BouncesPrevented]: dailyStat.bouncesPrevented,
        };

        return boringRoi;
      }

      case TabSections.EmailDataAccuracy:
      default: {
        const mainChartData: MainChartDataAccuracy = {
          percentages,
          date: dailyStat.date,
          [GeneralStatuses.ProspectsChecked]: dailyStat.prospectsChecked,
          [OutreachEmailStatuses.Valid]: dailyStat.valid,
          [OutreachEmailStatuses.Invalid]: dailyStat.invalid,
          [OutreachEmailStatuses.Questionable]: dailyStat.questionable,
          [OutreachEmailStatuses.NoEmail]: dailyStat.noEmail,
        };
        return mainChartData;
      }
    }
  });
};

const ProspectsTabManager: FC<ProspectTabManagerProps> = ({
  globalStats,
  isLoading,
  stats,
}) => {
  const [hoveredOnCard, setHoveredOnCard] = useState<TabSections | null>(null);

  const [globalStatsPercentage, setGlobalStatsPercentage] =
    useState<OrganizationStats>({
      prospectsChecked: 0,
      enrichedByBoring: 0,
      invalid: 0,
      valid: 0,
      questionable: 0,
      noEmail: 0,
      bouncesPrevented: 0,
    });

  const [selectedTab, setSelectedTab] = useState<TabSections>(
    TabSections.EmailDataAccuracy
  );
  const [chartKey, setChartKey] = useState(0);

  const forceRedraw = () => {
    setChartKey((prevKey) => prevKey + 1); // Increment key to force re-render
  };

  const statsToDisplay: MainChartTabSpecificData = useMemo(
    () => filterStatsByTab(stats, selectedTab),
    [stats, selectedTab]
  );

  useEffect(() => {
    setGlobalStatsPercentage(calculateStatsPercentages(globalStats));
  }, [globalStats]);

  const handleChangeTab = (tab: TabSections) => {
    setSelectedTab(tab);
    forceRedraw();
  };

  const displayValues = useMemo(() => {
    const valuesToReturn = {
      prospectsChecked: `${globalStats.prospectsChecked}`,
      valid: `${globalStatsPercentage.valid}%`,
      invalid: `${globalStatsPercentage.invalid}%`,
      questionable: `${globalStatsPercentage.questionable}%`,
      noEmail: `${globalStatsPercentage.noEmail}%`,
      enrichedByBoring: `${globalStatsPercentage.enrichedByBoring}%`,
      bouncesPrevented: `${globalStatsPercentage.bouncesPrevented}`,
    };
    switch (hoveredOnCard) {
      case TabSections.EmailDataAccuracy:
        valuesToReturn[OutreachEmailStatuses.Valid] = `${globalStats.valid}`;
        valuesToReturn[OutreachEmailStatuses.Invalid] =
          `${globalStats.invalid}`;
        valuesToReturn[OutreachEmailStatuses.Questionable] =
          `${globalStats.questionable}`;
        valuesToReturn[OutreachEmailStatuses.NoEmail] =
          `${globalStats.noEmail}`;
        break;
      case TabSections.BoringRoi:
        valuesToReturn[ExtendedEmailStatuses.EnrichedByBoring] =
          `${globalStats.enrichedByBoring}`;
        valuesToReturn[ExtendedEmailStatuses.BouncesPrevented] =
          `${globalStats.bouncesPrevented}`;
        break;
    }
    return valuesToReturn;
  }, [hoveredOnCard, globalStats, globalStatsPercentage]);

  return (
    <NoPaddingCard className="d-flex">
      <div>
        <ProspectsTabSection
          active={selectedTab === TabSections.ProspectsVerified}
          onClick={() => handleChangeTab(TabSections.ProspectsVerified)}
          onMouseEnter={() => setHoveredOnCard(TabSections.ProspectsVerified)}
          onMouseLeave={() => setHoveredOnCard(null)}
        >
          <TitleText variant={TextVariant.sm} className="text-uppercase mb-1">
            Prospects verified
          </TitleText>
          <StatCard>
            <NumberText variant={TextVariant.xl} color={COLORS.foregroundBrand}>
              {displayValues.prospectsChecked}
            </NumberText>
          </StatCard>
          <SubtitleText variant={TextVariant.sm}>
            Total prospects verified
          </SubtitleText>
        </ProspectsTabSection>
        <ProspectsTabSection
          active={selectedTab === TabSections.EmailDataAccuracy}
          onClick={() => handleChangeTab(TabSections.EmailDataAccuracy)}
          onMouseEnter={() => setHoveredOnCard(TabSections.EmailDataAccuracy)}
          onMouseLeave={() => setHoveredOnCard(null)}
          className="d-flex flex-column gap-4"
        >
          <TitleText variant={TextVariant.sm} className="text-uppercase">
            Email data accuracy
          </TitleText>
          <div className="d-flex flex-row gap-4 flex-wrap">
            <StatCard>
              <NumberText
                variant={TextVariant.xl}
                color={StatusColors[OutreachEmailStatuses.Valid]}
              >
                {displayValues.valid}
              </NumberText>
              <SubtitleText variant={TextVariant.sm}>Valid</SubtitleText>
            </StatCard>
            <StatCard>
              <NumberText
                variant={TextVariant.xl}
                color={StatusColors[OutreachEmailStatuses.Invalid]}
              >
                {displayValues.invalid}
              </NumberText>
              <SubtitleText variant={TextVariant.sm}>Invalid</SubtitleText>
            </StatCard>
          </div>
          <div className="d-flex flex-row gap-4">
            <StatCard>
              <NumberText
                variant={TextVariant.xl}
                color={StatusColors[OutreachEmailStatuses.Questionable]}
              >
                {displayValues.questionable}
              </NumberText>
              <SubtitleText variant={TextVariant.sm}>Questionable</SubtitleText>
            </StatCard>
            <StatCard>
              <NumberText
                variant={TextVariant.xl}
                color={StatusColors[OutreachEmailStatuses.NoEmail]}
              >
                {displayValues.noEmail}
              </NumberText>
              <SubtitleText variant={TextVariant.sm}>Missing</SubtitleText>
            </StatCard>
          </div>
        </ProspectsTabSection>
        <ProspectsTabSection
          active={selectedTab === TabSections.BoringRoi}
          onClick={() => handleChangeTab(TabSections.BoringRoi)}
          onMouseEnter={() => setHoveredOnCard(TabSections.BoringRoi)}
          onMouseLeave={() => setHoveredOnCard(null)}
          className="d-flex flex-column"
        >
          <TitleText variant={TextVariant.sm} className="text-uppercase mb-2">
            Boring ROI
          </TitleText>
          <div className="d-flex flex-row gap-4">
            <StatCard>
              <NumberText variant={TextVariant.xl}>
                {displayValues.bouncesPrevented}
              </NumberText>
              <SubtitleText variant={TextVariant.sm}>
                Bounces prevented
              </SubtitleText>
            </StatCard>
            {/* TODO: Add the BORING ROI fields */}
            <StatCard>
              <NumberText
                variant={TextVariant.xl}
                color={StatusColors[ExtendedEmailStatuses.EnrichedByBoring]}
              >
                {displayValues.enrichedByBoring}
              </NumberText>
              <SubtitleText variant={TextVariant.xl}>
                Emails enriched
              </SubtitleText>
            </StatCard>
          </div>
        </ProspectsTabSection>
      </div>
      <ChartContainer className="position-relative flex-grow-1 py-2 pl-3 pr-0">
        {isLoading ? (
          <Loading variant={LoadingVariant.BAR} />
        ) : (
          <MainChart
            key={chartKey}
            data={statsToDisplay}
            selectedTab={selectedTab}
          />
        )}
      </ChartContainer>
    </NoPaddingCard>
  );
};

export default ProspectsTabManager;
