import React, { FC, ReactNode, useState } from "react";
import Button, { ButtonVariants } from "./Button";
import Animation from "./Animation";
import { styled } from "@mui/system";
import { COLORS, FONTS, FONT_WEIGHTS, SPACINGS } from "../../common/consts";
import { makeShouldForwardProps } from "../../common/utils/ui";

interface Props {
  options: {
    label: ReactNode;
    value?: string;
    disabled?: boolean;
    children?: ReactNode;
    onClick?: () => void;
  }[];
  className?: string;
}

interface NavLinkProps {
  active: boolean;
}

const shouldForwardProp = makeShouldForwardProps<NavLinkProps>(["active"]);
const NavLink = styled(Button, {
  shouldForwardProp,
})<NavLinkProps>`
  color: ${({ active }) =>
    active ? COLORS.foregroundBrandLight : COLORS.foregroundPrimary};
  background-color: ${({ active }) =>
    active ? COLORS.backgroundBrandTab : "transparent"};
  border-radius: ${SPACINGS.xs};
  padding: 0 ${SPACINGS.md};
  font: ${FONTS.sm};
  border: none;
  font-weight: ${FONT_WEIGHTS.medium};
  cursor: pointer;
  height: 2.25rem;

  &:hover {
    color: ${({ active }) =>
      active ? COLORS.foregroundBrandLight : COLORS.foregroundPrimary};
    background-color: ${COLORS.backgroundBrandTabHover};
  }

  &:active,
  &.active {
    color: ${COLORS.foregroundBrandLight};
    background-color: ${COLORS.backgroundBrandTab} !important;
  }

  &:focus {
    color: ${COLORS.foregroundBrandLight} !important;
    background-color: ${({ active }) =>
      active
        ? COLORS.backgroundBrandTab
        : COLORS.backgroundBrandTabHover} !important;
    outline: none !important;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

const StyledBox = styled("div")`
  margin-bottom: ${SPACINGS.lg};
`;

const Tabs: FC<Props> = ({ options, className = "" }) => {
  const [activeTab, setActiveTab] = useState(options[0].value || "");

  return (
    <>
      <StyledBox>
        <ul className={`nav ${className}`}>
          {options.map((option) => (
            <li key={option.value} className="nav-item">
              <NavLink
                active={option.value === activeTab}
                type="button"
                variant={ButtonVariants.light}
                disabled={option.disabled}
                onClick={() => {
                  setActiveTab(option.value || "");
                  option.onClick?.();
                }}
              >
                {option.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </StyledBox>
      <Animation key={activeTab}>
        {options.find((option) => option.value === activeTab)?.children || null}
      </Animation>
    </>
  );
};

export default Tabs;
