import React, { FC } from "react";
import { Controller } from "react-hook-form";

import {
  validateCustomFieldMaxValue,
  validateCustomFieldMinValue,
  validateUniqueCustomField,
} from "../../common/utils/form";
import Text, { TextVariant } from "../../components/Display/Text";
import NumberInput from "../../components/DataInput/NumberInput";
import { FieldControllerPropsBase } from "./Onboarding";

type EmailStatusControllerProps = FieldControllerPropsBase & {
  timestampCustomFieldValue?: number | null;
  enrichedCustomFieldValue?: number | null;
  jobChangeCustomFieldValue?: number | null;
};

const EmailStatus: FC<EmailStatusControllerProps> = ({
  control,
  errors,
  timestampCustomFieldValue,
  enrichedCustomFieldValue,
  jobChangeCustomFieldValue,
  setValue,
}) => {
  return (
    <div className="d-flex flex-column gap-4 align-items-center">
      <Text variant={TextVariant.sm} className="text-left">
        Choose a custom field for us to mark with each prospect&apos;s email
        status
      </Text>
      <div className="w-40">
        <Controller
          name="organizationSettings.emailStatusCustomField"
          control={control}
          rules={{
            validate: (value) =>
              validateUniqueCustomField({
                isEnabled: true,
                value: value?.toString() || "",
                otherValues: [
                  timestampCustomFieldValue?.toString() || "",
                  enrichedCustomFieldValue?.toString() || "",
                  jobChangeCustomFieldValue?.toString() || "",
                ],
              }),
            min: validateCustomFieldMinValue({ minValue: 1, isEnabled: true }),
            max: validateCustomFieldMaxValue({
              maxValue: 150,
              isEnabled: true,
            }),
          }}
          render={({ field: { ref, value } }) => {
            return (
              <NumberInput
                label="Email status"
                inputRef={ref}
                value={value}
                onChange={(value: number) => {
                  setValue(
                    "organizationSettings.emailStatusCustomField",
                    value
                  );
                }}
                error={!!errors.organizationSettings?.emailStatusCustomField}
                helperText={
                  errors.organizationSettings?.emailStatusCustomField?.message
                }
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default EmailStatus;
