import React, { FC } from "react";
import { styled } from "@mui/system";
import { SPACINGS } from "../../common/consts";
import { useRecoilValue } from "recoil";
import { extensibilitySdkInitializedAtom } from "../../state/atoms";

const StyledContainer = styled("div")<{ paddingY: string }>`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: ${({ paddingY }) => paddingY} ${SPACINGS.xl};
`;

interface Props {
  children?: React.ReactNode;
}

const AppContainer: FC<Props> = ({ children }) => {
  const isExtensibilitySdkInitialized = useRecoilValue(
    extensibilitySdkInitializedAtom
  );

  return (
    <StyledContainer
      paddingY={isExtensibilitySdkInitialized ? SPACINGS.none : SPACINGS.xl}
    >
      {children}
    </StyledContainer>
  );
};

export default AppContainer;
