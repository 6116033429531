import React, { FC } from "react";
import { css, styled } from "@mui/system";
import { StatusColors, ChartValuesShapes } from "../../common/consts";
import { COLORS, SPACINGS, ChartLegendLabels } from "../../common/consts";
import {
  EmailStatuses,
  ExtendedEmailStatuses,
  GeneralStatuses,
  OutreachEmailStatuses,
} from "../../types/organizations";
import { SquareSvg } from "../../components/Display/Shapes";
import { TabSections } from "./ProspectsTabManager";
import Tooltip from "../../components/Display/Tooltip";

export type Percentages = Record<string, number>;

export interface CustomTooltipDayPayloadProps {
  percentages: Percentages;
  prospectsChecked?: number;
  valid?: number;
  invalid?: number;
  questionable?: number;
  noEmail?: number;
  enrichedByBoring?: number;
  bouncesPrevented?: number;
}

export interface CustomTooltipPayloadProps {
  name: string;
  payload: CustomTooltipDayPayloadProps;
}

export interface CustomTooltipProps {
  active?: boolean;
  payload?: CustomTooltipPayloadProps[];
  label?: string;
  selectedTab: TabSections;
}

interface LegendShapeWrapperProps {
  color: string;
}

const LegendItem = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: ${SPACINGS.xs};
`;

export const LegendShapeWrapper = styled("div")<LegendShapeWrapperProps>`
  ${({ color }) => css`
    color: ${color};
  `};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${SPACINGS.sm};
`;

export const getColorByEntryName = (name: string): string => {
  return StatusColors[name as EmailStatuses] || COLORS.borderPrimary;
};

export const getLabelByEntryName = (name: string): string => {
  return ChartLegendLabels[name as EmailStatuses] || name;
};

export const getShapeByEntryName = (name: string): FC => {
  return ChartValuesShapes[name as EmailStatuses] || SquareSvg;
};

const shouldDisplayWholeNumber = (
  name: string,
  selectedTab: TabSections
): boolean => {
  switch (name) {
    case ExtendedEmailStatuses.BouncesPrevented:
    case GeneralStatuses.ProspectsChecked:
      return true;
    case OutreachEmailStatuses.Invalid:
      return selectedTab === TabSections.BoringRoi;

    default:
      return false;
  }
};

const getPayloadWithAddedFields = ({
  payload,
  selectedTab,
}: {
  payload?: CustomTooltipPayloadProps[];
  selectedTab: TabSections;
}): CustomTooltipPayloadProps[] => {
  let newPayload = payload || [];

  switch (selectedTab) {
    case TabSections.EmailDataAccuracy: {
      newPayload = [
        {
          name: GeneralStatuses.ProspectsChecked,
          payload: {
            [GeneralStatuses.ProspectsChecked]:
              newPayload?.[0]?.payload?.prospectsChecked,
            percentages: {},
          },
        },
        ...newPayload,
      ];
      break;
    }
    case TabSections.BoringRoi:
      newPayload = [
        {
          name: OutreachEmailStatuses.Invalid,
          payload: {
            [OutreachEmailStatuses.Invalid]: newPayload?.[0]?.payload?.invalid,
            percentages: {},
          },
        },
        ...newPayload,
      ];
      break;
  }

  return newPayload;
};

const ChartTooltip: FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  selectedTab,
}) => {
  const newPayload = getPayloadWithAddedFields({ payload, selectedTab });

  return (
    <Tooltip
      open={active}
      title={label}
      content={newPayload.map((entry, index) => {
        const percentage = entry.payload.percentages[entry.name] || 0;
        const wholeNumber = entry.payload[entry.name as EmailStatuses] || 0;
        const valueToDisplay = shouldDisplayWholeNumber(entry.name, selectedTab)
          ? wholeNumber
          : `${percentage}%`;
        const ShapeComponent = getShapeByEntryName(entry.name);
        const entryColor = getColorByEntryName(entry.name);
        return (
          <LegendItem key={`item-${index}`}>
            <LegendShapeWrapper color={entryColor}>
              <ShapeComponent />
            </LegendShapeWrapper>
            {`${getLabelByEntryName(entry.name)}: ${valueToDisplay}`}
          </LegendItem>
        );
      })}
    />
  );
};

export default ChartTooltip;
