import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/system";
import { RADII, COLORS, FONT_FAMILIES } from "../../common/consts";

const TextFieldContainer = styled("div")`
  .MuiInputBase-root {
    border-radius: ${RADII.md};
    background-color: ${COLORS.backgroundWhite};
    color: ${COLORS.foregroundTertiary};

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${COLORS.outlineInputHover};
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${COLORS.outlineInputFocused};
        border-width: 1px;
      }
    }
  }

  .MuiInputBase-input {
    font-family: ${FONT_FAMILIES.subtitles};
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${COLORS.inputLabel};
  }
`;

const Input: React.ForwardRefRenderFunction<HTMLDivElement, TextFieldProps> = (
  { ...props },
  ref
) => {
  return (
    <>
      <TextFieldContainer>
        <TextField size="small" ref={ref} fullWidth {...props} />
      </TextFieldContainer>
    </>
  );
};

export default React.forwardRef(Input);
