import React, { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { getMyOrganization } from "../services/firebase";
import { signedInUserAtom } from "../state/atoms";
import Text, { TextVariant } from "../components/Display/Text";

const phrases = [
  "Please wait... We're setting up your account",
  "Lean back... Because magic takes a little time.",
  "Hang tight... Trying to convince the server to cooperate.",
  "Give it a moment... We're gathering the last pixels!",
  "Just a moment... We're almost there!",
];

const WaitForOnboarding: FC = () => {
  const signedInUser = useRecoilValue(signedInUserAtom);
  const [phraseIndex, setPhraseIndex] = useState(0);

  const { email, firstName, lastName } = signedInUser || {};

  useEffect(() => {
    const interval = setInterval(async () => {
      const { status, result } = await getMyOrganization();

      if (status === 200 && result) {
        clearInterval(interval);
        window.location.href = "/app/dashboard";
      }
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPhraseIndex((phraseIndex) => (phraseIndex + 1) % phrases.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  if (!signedInUser) {
    return <></>;
  }

  return (
    <div className="col-12">
      <div className="d-flex h-100 align-items-center justify-content-center m-auto">
        <div className="w-75 w-lg-400px">
          <Text variant={TextVariant.h4} className="mb-1">
            Hi, {firstName || lastName || email || "stranger"}! 👋
          </Text>
          <Text className="mb-1">{phrases[phraseIndex]}</Text>
        </div>
      </div>
    </div>
  );
};

export default WaitForOnboarding;
