import React, { FC, forwardRef, ReactNode } from "react";
import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  FormControl,
  InputLabel,
  Box,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS, FONTS, RADII } from "../../common/consts";
import { ReactComponent as SelectDown } from "../../assets/icons/select-down.svg";

export interface SelectOption {
  value: string;
  label?: ReactNode;
  disabled?: boolean;
}

type BaseSelectProps = Omit<MuiSelectProps<string>, "options" | "value">;

interface SelectProps extends BaseSelectProps {
  options: SelectOption[];
  variant?: "outlined" | "filled" | "standard";
  value?: string | string[];
}

const SelectBase = forwardRef<unknown, BaseSelectProps>(
  function SelectBase(props, ref) {
    return <MuiSelect {...props} ref={ref} />;
  }
);
SelectBase.displayName = "SelectBase";

const MenuItemBase = forwardRef<HTMLLIElement, MenuItemProps>(
  function MenuItemBase(props, ref) {
    return <MuiMenuItem {...props} ref={ref} />;
  }
);
MenuItemBase.displayName = "MenuItemBase";

const StyledInputLabel = styled(InputLabel)`
  background-color: ${COLORS.backgroundWhite};
  padding: 0 0.125rem;
`;

const StyledMenuItem = styled(MenuItemBase)`
  && {
    font: ${FONTS.sm};
    color: ${COLORS.foregroundTertiary};
    background-color: ${COLORS.backgroundWhite};
    &.Mui-selected {
      background: ${COLORS.backgroundBrandLight};
    }
    &:hover {
      background-color: ${COLORS.backgroundSecondary};
    }
  }
`;

const StyledSelect = styled(SelectBase)`
  && {
    border-radius: ${RADII.md};
    font: ${FONTS.md};
    font-weight: 275;
    color: ${COLORS.foregroundTertiary};
    background-color: ${COLORS.backgroundWhite};
    border: 1px solid ${COLORS.borderTertiary};
    &:hover {
      border: 1px solid ${COLORS.foregroundBrand};
    }
    &.Mui-focused {
      border: 1px solid ${COLORS.foregroundBrand};
      background-color: ${COLORS.backgroundBrandTab};
    }
    & .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
    &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .MuiInputBase-input {
    min-height: unset !important;
  }

  .MuiSvgIcon-root {
    color: ${COLORS.dropdownIconColor};
  }

  .MuiSelect-icon {
    top: calc(35% - 0.5em);
    color: ${COLORS.dropdownIconColor};
  }
`;

const StyledChipsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 4;
`;

const Select: FC<SelectProps> = ({
  options,
  label,
  multiple,
  renderValue,
  ...rest
}) => (
  <FormControl size="small">
    {label && <StyledInputLabel>{label}</StyledInputLabel>}
    <StyledSelect
      IconComponent={SelectDown}
      renderValue={
        multiple
          ? (selected) => (
              <StyledChipsContainer>
                {(selected as unknown as string[]).map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </StyledChipsContainer>
            )
          : renderValue
      }
      multiple={multiple}
      {...rest}
    >
      {options?.map(({ value, label, disabled }, index) => (
        <StyledMenuItem
          key={`select-option-${index}`}
          value={value}
          disabled={disabled}
        >
          {label}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  </FormControl>
);

export default Select;
