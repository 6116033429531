import React, { FC, ReactNode } from "react";
import Loading from "./Loading";
import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/system";
import { COLORS, FONTS, RADII, SPACINGS } from "../../common/consts";
import { makeShouldForwardProps } from "../../common/utils/ui";
import Text, { TextVariant } from "./Text";

export enum ButtonVariants {
  brand = "brand",
  light = "light",
  pill = "pill",
  text = "text",
}

export enum ButtonSizes {
  sm = "sm",
  md = "md",
  lg = "lg",
}

const getMuiSize = (size: ButtonSizes): "small" | "medium" | "large" => {
  switch (size) {
    case ButtonSizes.sm:
      return "small";
    case ButtonSizes.md:
      return "medium";
    case ButtonSizes.lg:
      return "large";
    default:
      return "medium";
  }
};

const getTextSize = (size: ButtonSizes): TextVariant => {
  switch (size) {
    case ButtonSizes.sm:
      return TextVariant.sm;
    case ButtonSizes.md:
      return TextVariant.md;
    case ButtonSizes.lg:
      return TextVariant.lg;
    default:
      return TextVariant.sm;
  }
};

interface CustomMuiButtonProps {
  active?: boolean;
}

const shouldForwardProp = makeShouldForwardProps<CustomMuiButtonProps>([
  "active",
]);

const CustomMuiButton = styled(MuiButton, {
  shouldForwardProp,
})<CustomMuiButtonProps>`
  && {
    font: ${FONTS.sm};
    text-transform: none;
    border-radius: ${RADII.full};
    padding: ${SPACINGS.sm} ${SPACINGS.md};
    color: ${({ active }) =>
      active ? COLORS.foregroundSecondaryLight : COLORS.foregroundPrimary};
  }
  &:hover {
    background-color: ${COLORS.backgroundSecondary};
  }
`;

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  loading?: boolean;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  fullWidth?: boolean;
  active?: boolean;
}

const Button: FC<ButtonProps> = ({
  children,
  className = "",
  startIcon,
  endIcon,
  loading,
  variant = ButtonVariants.brand,
  size = ButtonSizes.sm,
  fullWidth = false,
  active,
  ...rest
}) => {
  const isPillVariant = variant === ButtonVariants.pill;

  if (isPillVariant) {
    return (
      <CustomMuiButton
        fullWidth={fullWidth}
        className={className}
        size={getMuiSize(size)}
        startIcon={loading ? <Loading /> : startIcon}
        endIcon={endIcon}
        active={active}
        {...rest}
      >
        {children}
      </CustomMuiButton>
    );
  }

  const buttonClassName = `btn btn-${variant} btn-${size} ${active ? "active" : ""} ${
    fullWidth ? "w-100" : ""
  } ${className}`;

  return (
    <button className={buttonClassName} {...rest}>
      <div className="d-flex gap-2">
        {(loading || startIcon) && (
          <div>{loading ? <Loading /> : startIcon}</div>
        )}
        <Text variant={getTextSize(size)} color="inherit">
          {children}
        </Text>
        {endIcon && <div>{endIcon}</div>}
      </div>
    </button>
  );
};

export default Button;
