import React, { FC } from "react";

import Text, { TextVariant } from "../../components/Display/Text";

const OutreachAdminPermissions: FC = () => {
  return (
    <Text variant={TextVariant.md}>
      Do you have admin permissions in Outreach?
    </Text>
  );
};

export default OutreachAdminPermissions;
