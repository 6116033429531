import React, { FC, useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Skeleton } from "@mui/material";
import { css, styled } from "@mui/system";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { AccountSettings } from "../types/organizations";
import { AppUser, ProfileType } from "../types/users";
import { organizationAtom, signedInUserAtom } from "../state/atoms";
import {
  convertDbAccountToAppAccount,
  getDbUser,
  getMyOrganization,
  updateOrganizationSettings,
  signUserOut,
  convertDbOrganizationSettingsToAppOrganizationSettings,
  convertAppOrganizationSettingsToDbOrganizationSettings,
  subToEmailsInQueue,
  getOrgWebhookStatus,
  updateOrgWebhookStatus,
} from "../services/firebase";
import Card from "../components/Display/Card";
import Button, {
  ButtonSizes,
  ButtonVariants,
} from "../components/Display/Button";
import Text, { TextVariant } from "../components/Display/Text";
import Select, { SelectOption } from "../components/DataInput/Select";
import Switch from "../components/DataInput/Switch";
import Input from "../components/DataInput/Input";
import Grid from "../components/Display/Grid";
import { COLORS, GAPS, RADII, SKELETON_SIZES } from "../common/consts";
import { removeUndefinedAndNull } from "../common/utils/data";
import {
  validateUniqueCustomField,
  validateCustomFieldMinValue,
  validateCustomFieldMaxValue,
} from "../common/utils/form";
import { DEFAULT_CUSTOM_FIELD_IDS } from "../common/consts";
import { CircleSvg } from "../components/Display/Shapes";
import ProgressBar from "../components/Display/ProgressBar";
import { makeShouldForwardProps } from "../common/utils/ui";

const profileOptions: SelectOption[] = [
  {
    value: ProfileType.ADMIN,
    label: "Admin",
  },
  {
    value: ProfileType.DEFAULT,
    label: "Default",
  },
  {
    value: ProfileType.LEADEARSHIP,
    label: "Leadership",
  },
];

interface ShapeColorProps {
  color: string;
}

const ShapeColor = styled("div")<ShapeColorProps>`
  ${({ color }) => css`
    color: ${color};
    transition: color 0.15s ease-in-out;
    margin-bottom: ${RADII.xs};
  `};
`;

interface BorderedCardProps {
  width?: string;
}
const shouldForwardProp = makeShouldForwardProps<BorderedCardProps>(["width"]);
const BorderedCard = styled("div", { shouldForwardProp })<BorderedCardProps>`
  border: ${RADII.xxs} solid ${COLORS.borderTertiary};
  border-radius: ${RADII.sm};
  box-sizing: border-box;
  background-color: ${COLORS.backgroundSecondaryCard};
  width: ${({ width }: BorderedCardProps) => width || "100%"};
`;

const Settings: FC = () => {
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const [organization, setOrganization] = useRecoilState(organizationAtom);
  const [loading, setLoading] = useState(false);
  const [isIntegrationChecked, setIsIntegrationChecked] = useState<
    boolean | undefined
  >(undefined);
  const [isIntegrationUpdateLoading, setIsIntegrationUpdateLoading] =
    useState(false);
  const [emailsInQueue, setEmailsInQueue] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { isDirty, errors },
  } = useForm<AccountSettings>({
    defaultValues: {
      userSettings: {
        firstName: signedInUser?.firstName || "",
        title: signedInUser?.title || "",
        profile: signedInUser?.profile || ProfileType.DEFAULT,
        phoneNumber: signedInUser?.phoneNumber || "",
      },
      organizationSettings: {
        isEmailStatusCustomFieldEnabled:
          organization?.isEmailStatusCustomFieldEnabled || false,
        emailStatusCustomField: organization?.emailStatusCustomField,
        disableOptOutProspects: !organization?.disableOptOutProspects,
        isTimestampCustomFieldEnabled:
          organization?.isTimestampCustomFieldEnabled || false,
        timestampCustomField: organization?.timestampCustomField,
        isEnrichedCustomFieldEnabled:
          organization?.isEnrichedCustomFieldEnabled || false,
        enrichedCustomField: organization?.enrichedCustomField,
        isJobChangeCustomFieldEnabled:
          organization?.isJobChangeCustomFieldEnabled || false,
        jobChangeCustomField: organization?.jobChangeCustomField,
      },
    },
  });
  const onSubmit: SubmitHandler<AccountSettings> = (data) =>
    handleFormSubmit(data);

  const emailStatusCustomFieldValue = watch(
    "organizationSettings.emailStatusCustomField"
  );
  const timestampCustomFieldValue = watch(
    "organizationSettings.timestampCustomField"
  );
  const enrichedCustomFieldValue = watch(
    "organizationSettings.enrichedCustomField"
  );

  const jobChangeCustomFieldValue = watch(
    "organizationSettings.jobChangeCustomField"
  );

  const isEmailStatusCustomFieldEnabled = watch(
    "organizationSettings.isEmailStatusCustomFieldEnabled"
  );
  const isTimestampCustomFieldEnabled = watch(
    "organizationSettings.isTimestampCustomFieldEnabled"
  );
  const isEnrichedCustomFieldEnabled = watch(
    "organizationSettings.isEnrichedCustomFieldEnabled"
  );

  const isJobChangeCustomFieldEnabled = watch(
    "organizationSettings.isJobChangeCustomFieldEnabled"
  );

  const timestampCustomFieldNumberValue =
    Number(timestampCustomFieldValue) || null;
  const enrichedCustomFieldNumberValue =
    Number(enrichedCustomFieldValue) || null;
  const jobChangeCustomFieldNumberValue =
    Number(jobChangeCustomFieldValue) || null;

  const handleFormSubmit = async (data: AccountSettings) => {
    if (!signedInUser || !organization) return;
    setLoading(true);

    try {
      const updatedSettings: AccountSettings = {
        ...data,
        organizationSettings: {
          ...data.organizationSettings,
          disableOptOutProspects:
            !data.organizationSettings.disableOptOutProspects,
          timestampCustomField: isTimestampCustomFieldEnabled
            ? timestampCustomFieldNumberValue
            : null,
          enrichedCustomField: isEnrichedCustomFieldEnabled
            ? enrichedCustomFieldNumberValue
            : null,
          jobChangeCustomField: isJobChangeCustomFieldEnabled
            ? jobChangeCustomFieldNumberValue
            : null,
        },
      };

      const convertedSettings =
        convertAppOrganizationSettingsToDbOrganizationSettings(
          updatedSettings.organizationSettings
        );

      updatedSettings.organizationSettings = convertedSettings;

      await updateOrganizationSettings(
        removeUndefinedAndNull<AccountSettings>(updatedSettings)
      );

      const [organization, dbUser] = await Promise.all([
        getMyOrganization(),
        getDbUser(signedInUser.uid),
      ]);
      const updatedUser = convertDbAccountToAppAccount(
        dbUser,
        organization.result
      );
      setOrganization(
        convertDbOrganizationSettingsToAppOrganizationSettings(
          organization.result || {}
        )
      );
      setSignedInUser(updatedUser as AppUser);
      toast.success("Settings updated successfully");
    } catch (error) {
      console.error("Failed to update settings:", error?.toString());
    } finally {
      setLoading(false);
    }
  };

  const handleIntegrationStatusUpdate = async () => {
    try {
      setIsIntegrationUpdateLoading(true);
      await updateOrgWebhookStatus({ active: !isIntegrationChecked });
      const webhookStatus = await getOrgWebhookStatus();
      setIsIntegrationChecked(webhookStatus.result?.active);
      setIsIntegrationUpdateLoading(false);
      toast.success("Account status updated successfully");
    } catch (error) {
      console.error("Failed to update webhook status:", error?.toString());
      setIsIntegrationUpdateLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = subToEmailsInQueue(
      organization?.id || "",
      (emailsInQueue) => {
        setEmailsInQueue(emailsInQueue);
      }
    );

    return () => unsubscribe?.();
  }, [emailsInQueue, organization?.id]);

  useEffect(() => {
    reset({
      userSettings: {
        firstName: signedInUser?.firstName || "",
        title: signedInUser?.title || "",
        profile: signedInUser?.profile || ProfileType.DEFAULT,
        phoneNumber: signedInUser?.phoneNumber || "",
      },
      organizationSettings: {
        isEmailStatusCustomFieldEnabled:
          organization?.isEmailStatusCustomFieldEnabled || false,
        emailStatusCustomField: organization?.emailStatusCustomField,
        disableOptOutProspects: !organization?.disableOptOutProspects || false,
        isTimestampCustomFieldEnabled:
          organization?.isTimestampCustomFieldEnabled || false,
        timestampCustomField: organization?.timestampCustomField,
        isEnrichedCustomFieldEnabled:
          organization?.isEnrichedCustomFieldEnabled || false,
        enrichedCustomField: organization?.enrichedCustomField,
        isJobChangeCustomFieldEnabled:
          organization?.isJobChangeCustomFieldEnabled || false,
        jobChangeCustomField: organization?.jobChangeCustomField,
      },
    });
  }, [signedInUser, organization, reset]);

  useEffect(() => {
    const updateWebhookStatus = async () => {
      try {
        const webhookStatus = await getOrgWebhookStatus();
        setIsIntegrationChecked(webhookStatus.result?.active);
      } catch (error) {
        console.error("Failed to get webhook status:", error?.toString());
      }
    };

    updateWebhookStatus();
  }, []);

  return (
    <Card className="w-md-100 w-1040px p-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between" gap={GAPS.lg}>
          <Grid container justifyContent="space-between" gap={GAPS.lg}>
            <Grid gap={GAPS.md}>
              <Grid container direction="column" gap={GAPS.lg}>
                <Grid>
                  <Text variant={TextVariant.xl}>Account info</Text>
                </Grid>
                <Grid>
                  <Input
                    label="Name"
                    type="text"
                    {...register("userSettings.firstName")}
                  />
                </Grid>
                <Grid>
                  <Input
                    label="Title"
                    type="text"
                    {...register("userSettings.title")}
                  />
                </Grid>
                <Grid>
                  <Input
                    label="Organization"
                    type="text"
                    disabled
                    value={signedInUser?.organizationName}
                  />
                </Grid>
                <Grid>
                  <Input
                    label="Email"
                    type="text"
                    helperText="Contact support to change your email."
                    disabled
                    value={signedInUser?.email}
                  />
                </Grid>
                <Grid>
                  <Select
                    label="Profile"
                    defaultValue={signedInUser?.profile || ProfileType.DEFAULT}
                    options={profileOptions}
                    {...register("userSettings.profile")}
                  />
                </Grid>
                <Grid>
                  <Input
                    label="Phone"
                    type="tel"
                    {...register("userSettings.phoneNumber")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid gap={GAPS.lg}>
              <Grid container direction="column" gap={GAPS.md}>
                <Grid>
                  <Text variant={TextVariant.xl}>Workflows</Text>
                </Grid>
                <Grid container>
                  <Controller
                    control={control}
                    name="organizationSettings.disableOptOutProspects"
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                        label="Opt-out prospects with invalid email"
                      />
                    )}
                  />
                </Grid>
                <Grid container>
                  <Controller
                    control={control}
                    name="organizationSettings.isEmailStatusCustomFieldEnabled"
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                        label="Mark a custom field with each prospect’s email status"
                      />
                    )}
                  />
                </Grid>
                <Grid container>
                  <Input
                    label="Email status"
                    placeholder={`${DEFAULT_CUSTOM_FIELD_IDS.emailStatusCustomFieldId}`}
                    type="tel"
                    error={
                      !!errors.organizationSettings?.emailStatusCustomField
                    }
                    helperText={
                      errors.organizationSettings?.emailStatusCustomField
                        ?.message
                    }
                    disabled={!isEmailStatusCustomFieldEnabled}
                    {...register(
                      "organizationSettings.emailStatusCustomField",
                      {
                        validate: (value) =>
                          validateUniqueCustomField({
                            isEnabled: isEmailStatusCustomFieldEnabled,
                            value: value?.toString() || "",
                            otherValues: [
                              timestampCustomFieldValue?.toString() || "",
                              enrichedCustomFieldValue?.toString() || "",
                              jobChangeCustomFieldValue?.toString() || "",
                            ],
                          }),
                        min: validateCustomFieldMinValue({
                          minValue: 1,
                          isEnabled: !!isEmailStatusCustomFieldEnabled,
                        }),
                        max: validateCustomFieldMaxValue({
                          maxValue: 150,
                          isEnabled: !!isEmailStatusCustomFieldEnabled,
                        }),
                      }
                    )}
                    required={isEmailStatusCustomFieldEnabled}
                  />
                </Grid>
                <Grid container>
                  <Controller
                    control={control}
                    name="organizationSettings.isTimestampCustomFieldEnabled"
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                        label="Mark a custom field with the date each email was verified"
                      />
                    )}
                  />
                </Grid>
                <Grid container>
                  <Input
                    label="Verification date"
                    placeholder={`${DEFAULT_CUSTOM_FIELD_IDS.timestampCustomFieldId}`}
                    type="tel"
                    error={!!errors.organizationSettings?.timestampCustomField}
                    helperText={
                      errors.organizationSettings?.timestampCustomField?.message
                    }
                    disabled={!isTimestampCustomFieldEnabled}
                    {...register("organizationSettings.timestampCustomField", {
                      validate: (value) =>
                        validateUniqueCustomField({
                          isEnabled: isTimestampCustomFieldEnabled,
                          value: value?.toString() || "",
                          otherValues: [
                            emailStatusCustomFieldValue?.toString() || "",
                            enrichedCustomFieldValue?.toString() || "",
                            jobChangeCustomFieldValue?.toString() || "",
                          ],
                        }),
                      min: validateCustomFieldMinValue({
                        minValue: 1,
                        isEnabled: !!isTimestampCustomFieldEnabled,
                      }),
                      max: validateCustomFieldMaxValue({
                        maxValue: 150,
                        isEnabled: !!isTimestampCustomFieldEnabled,
                      }),
                    })}
                    required={isTimestampCustomFieldEnabled}
                  />
                </Grid>
                {organization?.isEnrichmentEnabled && (
                  <>
                    <Grid container>
                      <Controller
                        control={control}
                        name="organizationSettings.isEnrichedCustomFieldEnabled"
                        render={({ field: { onChange, value } }) => (
                          <Switch
                            checked={value}
                            onChange={(e) => {
                              onChange(e.target.checked);
                            }}
                            label="Mark a custom field with the source each email came from"
                          />
                        )}
                      />
                    </Grid>
                    <Grid container>
                      <Input
                        label="Email source"
                        placeholder={`${DEFAULT_CUSTOM_FIELD_IDS.enrichedCustomFieldId}`}
                        type="tel"
                        error={
                          !!errors.organizationSettings?.enrichedCustomField
                        }
                        helperText={
                          errors.organizationSettings?.enrichedCustomField
                            ?.message
                        }
                        disabled={!isEnrichedCustomFieldEnabled}
                        {...register(
                          "organizationSettings.enrichedCustomField",
                          {
                            validate: (value) =>
                              validateUniqueCustomField({
                                isEnabled: isEnrichedCustomFieldEnabled,
                                value: value?.toString() || "",
                                otherValues: [
                                  timestampCustomFieldValue?.toString() || "",
                                  emailStatusCustomFieldValue?.toString() || "",
                                ],
                              }),
                            min: validateCustomFieldMinValue({
                              minValue: 1,
                              isEnabled: !!isEnrichedCustomFieldEnabled,
                            }),
                            max: validateCustomFieldMaxValue({
                              maxValue: 150,
                              isEnabled: !!isEnrichedCustomFieldEnabled,
                            }),
                          }
                        )}
                        required={isEnrichedCustomFieldEnabled}
                      />
                    </Grid>

                    <Grid container>
                      <Controller
                        control={control}
                        name="organizationSettings.isJobChangeCustomFieldEnabled"
                        render={({ field: { onChange, value } }) => (
                          <Switch
                            checked={value}
                            onChange={(e) => {
                              onChange(e.target.checked);
                            }}
                            label="Mark a custom field when an enriched prospect switched companies"
                          />
                        )}
                      />
                    </Grid>
                    <Grid container>
                      <Input
                        label="Job change"
                        placeholder={`${DEFAULT_CUSTOM_FIELD_IDS.jobChangeCustomFieldId}`}
                        type="tel"
                        error={
                          !!errors.organizationSettings?.jobChangeCustomField
                        }
                        helperText={
                          errors.organizationSettings?.jobChangeCustomField
                            ?.message
                        }
                        disabled={!isJobChangeCustomFieldEnabled}
                        {...register(
                          "organizationSettings.jobChangeCustomField",
                          {
                            validate: (value) =>
                              validateUniqueCustomField({
                                isEnabled: isJobChangeCustomFieldEnabled,
                                value: value?.toString() || "",
                                otherValues: [
                                  timestampCustomFieldValue?.toString() || "",
                                  emailStatusCustomFieldValue?.toString() || "",
                                  enrichedCustomFieldValue?.toString() || "",
                                ],
                              }),
                            min: validateCustomFieldMinValue({
                              minValue: 1,
                              isEnabled: !!isJobChangeCustomFieldEnabled,
                            }),
                            max: validateCustomFieldMaxValue({
                              maxValue: 150,
                              isEnabled: !!isJobChangeCustomFieldEnabled,
                            }),
                          }
                        )}
                        required={isJobChangeCustomFieldEnabled}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid gap={GAPS.lg}>
              <Grid container direction="column" gap={GAPS.md}>
                <Grid>
                  <Text variant={TextVariant.xl}>Active verifications</Text>
                </Grid>
                <Grid>
                  <BorderedCard width="18rem">
                    <Grid
                      container
                      direction="column"
                      className="p-3"
                      gap={GAPS.sm}
                    >
                      {emailsInQueue === 0 ? (
                        <>
                          <Text
                            color={COLORS.foregroundSecondary}
                            variant={TextVariant.sm}
                          >
                            All of your emails have been verified
                          </Text>
                        </>
                      ) : (
                        <>
                          <Text variant={TextVariant.h4}>
                            {!isNaN(emailsInQueue) ? emailsInQueue : "N/A"}
                          </Text>
                          <Text
                            color={COLORS.foregroundSecondary}
                            variant={TextVariant.sm}
                          >
                            emails in queue to be verified
                          </Text>
                        </>
                      )}
                      <ProgressBar
                        variant="determinate"
                        value={emailsInQueue}
                        maxValue={100}
                      />
                    </Grid>
                  </BorderedCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid gap={GAPS.lg}>
              <Grid justifyContent="space-between" gap={GAPS.lg}>
                <Grid container direction="column" gap={GAPS.md}>
                  <Grid>
                    <Text variant={TextVariant.xl}>Integration</Text>
                  </Grid>
                  <Grid>
                    <BorderedCard width="32rem">
                      <Grid
                        container
                        direction="column"
                        className="p-3"
                        gap={GAPS.sm}
                      >
                        <Text
                          color={COLORS.foregroundSecondaryDark}
                          variant={TextVariant.h6}
                        >
                          {organization?.name}&apos;s Outreach account
                        </Text>
                        {isIntegrationChecked === undefined ? (
                          <Skeleton width={"100%"} height={SKELETON_SIZES.sm} />
                        ) : (
                          <Grid container justifyContent="space-between">
                            <Grid
                              display="flex"
                              alignItems="center"
                              gap={GAPS.xs}
                            >
                              <ShapeColor
                                color={
                                  isIntegrationUpdateLoading
                                    ? COLORS.integrationLoading
                                    : isIntegrationChecked
                                      ? COLORS.integrationConnected
                                      : COLORS.integrationDisconnected
                                }
                              >
                                <CircleSvg />
                              </ShapeColor>
                              <Text
                                color={COLORS.foregroundSecondary}
                                variant={TextVariant.sm}
                              >
                                {isIntegrationUpdateLoading
                                  ? "Loading"
                                  : isIntegrationChecked
                                    ? "Connected"
                                    : "Disconnected"}
                              </Text>
                            </Grid>
                            <Grid>
                              <Switch
                                checked={isIntegrationChecked}
                                onChange={() => handleIntegrationStatusUpdate()}
                                disabled={
                                  isIntegrationUpdateLoading ||
                                  isIntegrationChecked === undefined
                                }
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </BorderedCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" gap={GAPS.lg}>
            <Button
              variant={ButtonVariants.light}
              size={ButtonSizes.md}
              onClick={signUserOut}
              type="button"
            >
              Sign Out
            </Button>
            <Button
              variant={ButtonVariants.brand}
              size={ButtonSizes.md}
              type="submit"
              disabled={!isDirty || loading}
              loading={loading}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default Settings;
