import React, { FC } from "react";

import Text, { TextVariant } from "../../components/Display/Text";

const WatchVideos: FC = () => {
  return (
    <Text variant={TextVariant.sm}>
      Want to watch a video to see how the plugin works?
    </Text>
  );
};

export default WatchVideos;
