import React, { FC } from "react";
import {
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
  GridToolbarContainer,
  GridToolbarExport,
  GridSlotsComponent,
  GridValidRowModel,
  GridRowsProp,
  GridColDef,
} from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import {
  COLORS,
  FONTS,
  FONT_WEIGHTS,
  RADII,
  SPACINGS,
} from "../../common/consts";

type PageSizeOption = number | { value: number; label: string };
type PageSizeOptionsType = readonly PageSizeOption[] | undefined;
type SlotsType = Partial<GridSlotsComponent> | undefined;

export type DataGridCol<T extends GridValidRowModel> = GridColDef<T>;
export type DataGridRow<T extends GridValidRowModel> = GridRowsProp<T>[0];

export interface DataGridProps<T extends GridValidRowModel>
  extends MuiDataGridProps {
  columns: DataGridCol<T>[];
  rows: DataGridRow<T>[];
}

const DataGridContainer = styled("div")`
  .MuiDataGrid-root {
    .MuiDataGrid-cell {
      border: none;
      display: inline-flex;
      color: ${COLORS.foregroundPrimary};
      font: ${FONTS.sm};
      align-items: center;
      justify-content: flex-start;
    }
    .MuiDataGrid-cell:hover {
      background-color: ${COLORS.backgroundBrandTabHover};
    }
    .MuiDataGrid-cell:focus {
      outline: none;
    }
    .MuiDataGrid-columnHeader {
      display: inline-flex;
      background-color: ${COLORS.backgroundBrandTable};
      color: ${COLORS.foregroundBrandTable};
      font: ${FONTS.sm};
      font-weight: ${FONT_WEIGHTS.semibold};
      font-size: 0.813rem;
      align-items: center;
      justify-content: flex-start;
      outline: none !important;
    }
    .MuiDataGrid-columnHeaderTitle {
      white-space: normal;
      word-wrap: break-word;
    }
    .MuiDataGrid-sortIcon {
      color: ${COLORS.foregroundBrandLight};
    }
    .MuiDataGrid-columnHeader:hover {
      color: ${COLORS.foregroundSecondary};
    }
    .MuiDataGrid-columnHeader:focus {
      outline: none;
      color: ${COLORS.foregroundBrandTable};
    }
    .MuiDataGrid-footerContainer {
      background-color: ${COLORS.backgroundPrimary};
      border-top: none;
      border-radius: ${RADII.md};
    }
    .MuiTablePagination-root {
      color: ${COLORS.foregroundPrimary};
    }
    .MuiTablePagination-displayedRows {
      font: ${FONTS.md};
      font-weight: ${FONT_WEIGHTS.light};
      margin-bottom: 0;
    }
    .MuiDataGrid-row:nth-of-type(even) {
      background-color: ${COLORS.backgroundBrandTable};
    }
    .MuiDataGrid-row:nth-of-type(odd) {
      background-color: ${COLORS.backgroundPrimary};
    }
  }
`;

const StyledToolBarContainer = styled(GridToolbarContainer)`
  background-color: ${COLORS.backgroundPrimary};
  display: flex;
  justify-content: flex-end;
  padding: ${SPACINGS.xl} ${SPACINGS.xl};
  border-bottom: 1px solid ${COLORS.borderDark};
  .MuiButton-text {
    color: ${COLORS.foregroundPrimary};
    border: none;
    text-transform: capitalize;
    border-radius: ${RADII.xl};
    padding: ${SPACINGS.sm} ${SPACINGS.sm};
    font: ${FONTS.sm};
  }
`;

const CustomToolbar: FC = (props) => {
  return (
    <StyledToolBarContainer {...props}>
      <GridToolbarExport />
    </StyledToolBarContainer>
  );
};

const initialState: GridInitialStateCommunity = {
  pagination: {
    paginationModel: {
      pageSize: 10,
    },
  },
};

const pageSizeOptions: PageSizeOptionsType = [3];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const slots: SlotsType = {
  toolbar: CustomToolbar,
};

const DataGrid = <T extends GridValidRowModel>({
  columns,
  rows,
  ...props
}: DataGridProps<T>): JSX.Element => {
  return (
    <DataGridContainer>
      <MuiDataGrid
        disableColumnFilter
        disableColumnMenu
        disableColumnResize
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
        initialState={initialState}
        pageSizeOptions={pageSizeOptions}
        // slots={slots}
        autoHeight
        columns={columns}
        rows={rows}
        {...props}
      />
    </DataGridContainer>
  );
};

export default DataGrid;
