import React, { FC, useMemo } from "react";
import { GAPS } from "../../common/consts";
import Card from "../../components/Display/Card";
import Grid from "../../components/Display/Grid";
import Text, { TextVariant } from "../../components/Display/Text";
import DataGrid, {
  DataGridCol,
  DataGridRow,
} from "../../components/Display/DataGrid";
import { OrgDisplayUser } from "../../types/users";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../state/atoms";

const columns: DataGridCol<OrgDisplayUser>[] = [
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
];

const AdminUsers: FC = () => {
  const organization = useRecoilValue(organizationAtom);

  const users = useMemo<DataGridRow<OrgDisplayUser>[]>(
    () => (organization?.allowedOwnerEmails || []).map((email) => ({ email })),
    [organization]
  );

  const getRowId = (row: OrgDisplayUser) => row.uid || row.email;

  return (
    <>
      <Card className="p-3">
        <Grid container direction="column" gap={GAPS.sm}>
          <Grid justifyContent="space-between" container>
            <Text variant={TextVariant.xl}>Users</Text>
          </Grid>
          <Grid>
            <DataGrid<OrgDisplayUser>
              rows={users}
              columns={columns}
              getRowId={getRowId}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AdminUsers;
