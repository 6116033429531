import React, { FC } from "react";
import { useRecoilState } from "recoil";

import { BouncePrevention } from "../../types/forms";
import { onboardingFormAtom } from "../../state/atoms";
import RadioButton from "../../components/Display/RadioButton";
import RadioButtonsGroup from "../../components/Display/RadioButtonsGroup";
import Text, { TextVariant } from "../../components/Display/Text";

const BouncePreventing: FC = () => {
  const [onboardingForm, setOnboardingForm] =
    useRecoilState(onboardingFormAtom);

  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOnboardingForm((onboardingForm) => ({
      ...onboardingForm,
      bouncePrevention: event.target.value as BouncePrevention,
    }));
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-column gap-3">
        <Text variant={TextVariant.h6} className="text-center">
          Bounce prevention
        </Text>
        <Text variant={TextVariant.sm}>
          Choose what should happen when a prospect&apos;s email address is
          invalid.
        </Text>
      </div>
      <div className="w-100">
        <RadioButtonsGroup
          onChange={handleSelectionChange}
          defaultValue={BouncePrevention.AddSequences}
          value={onboardingForm.bouncePrevention}
        >
          <RadioButton
            label="Allow them to be sequenced but skip their email steps"
            value={BouncePrevention.AddSequences}
          />
          <RadioButton
            label="Block them from being added to sequences"
            value={BouncePrevention.BlockSequences}
          />
          <RadioButton label="Do nothing" value={BouncePrevention.DoNothing} />
        </RadioButtonsGroup>
      </div>
    </div>
  );
};

export default BouncePreventing;
