import React, { FC } from "react";

import Text, { TextVariant } from "../../components/Display/Text";

const Notice: FC = () => {
  return (
    <div className="d-flex flex-column gap-3">
      <Text variant={TextVariant.sm}>
        Note: the way that boring automatically prevents invalid emails from
        being sent is by enabling email opt-outs for prospects with invalid
        emails.
      </Text>
      <Text variant={TextVariant.sm}>
        By opting them out of email, it allows Outreach to automatically skip
        email steps in their sequences, and prevents reps from accidentally
        sending them an email.
      </Text>
      <Text variant={TextVariant.sm}>
        These opt-outs do not act as global opt-outs do in your CRM. They are
        revertible and do not carry any legal implications.
      </Text>
    </div>
  );
};

export default Notice;
