import React, { FC } from "react";
import { styled } from "@mui/system";

import { COLORS } from "../../common/consts";
import Text, { TextVariant } from "../../components/Display/Text";

import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as BoringIcon } from "../../assets/icons/boring-icon.svg";
import { ReactComponent as OutreachIcon } from "../../assets/icons/outreach-icon.svg";

const StyledIconWrapper = styled("div")<{ background: string }>`
  background: ${({ background }) => background};
`;

const ConnectOutreach: FC = () => {
  return (
    <>
      <Text variant={TextVariant.md}>Connect boring to Outreach</Text>
      <div className="d-flex w-100 justify-content-center align-items-center">
        <StyledIconWrapper
          className="rounded w-55px h-55px"
          background="#1921FF"
        >
          <BoringIcon
            className="rounded w-55px h-55px"
            color={COLORS.backgroundWhite}
          />
        </StyledIconWrapper>
        <div className="d-flex flex-column justify-content-center align-items-center w-55px h-55px">
          <ArrowLeft />
          <ArrowRight />
        </div>
        <StyledIconWrapper
          className="rounded w-55px h-55px"
          background={COLORS.foregroundBrand}
        >
          <OutreachIcon color={COLORS.foregroundWhite} />
        </StyledIconWrapper>
      </div>
    </>
  );
};

export default ConnectOutreach;
