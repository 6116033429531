import React, {
  FC,
  useEffect,
  useMemo,
  useState,
  useCallback,
  ReactNode,
} from "react";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { styled } from "@mui/system";

import { COLORS } from "../common/consts";
import { DbOrganizationTrialDetails } from "../types/organizations";
import { Errors } from "../types/errors";
import { setupProspectWebhook, signUserOut } from "../services/firebase";
import { signedInUserAtom } from "../state/atoms";
import Tabs from "../components/Display/Tabs";
import Button, { ButtonVariants } from "../components/Display/Button";
import FormHelper, { Severity } from "../components/DataInput/FormHelper";
import Text, { TextVariant } from "../components/Display/Text";
import Animation from "../components/Display/Animation";
import Dialog from "../components/Display/Dialog";

import Insights from "./Insights/Insights";
import Settings from "./Settings";
import Users from "./Users/Users";

dayjs.extend(relativeTime);
dayjs.extend(utc);

const StyledValue = styled("span")`
  color: ${COLORS.integrationDisconnected};
`;

const scheduleMeetingUrl = "https://hey.boringplugins.com/c/binny/t/12";

interface TrialExpiry {
  isTrialExpired: boolean;
  relativeTime: string | null;
}

const calculateTrialExpiry = (
  trialDetails?: DbOrganizationTrialDetails
): TrialExpiry => {
  const currentTimestamp = dayjs();
  const expiresAt = trialDetails?.expiresAt && dayjs(trialDetails.expiresAt);
  const isTrialExpired = !!(expiresAt && currentTimestamp.isAfter(expiresAt));
  const relativeTime =
    (!isTrialExpired && expiresAt && expiresAt.fromNow(true)) || null;

  return {
    isTrialExpired,
    relativeTime,
  };
};

const getTrialMessage = (
  trialExpiry: TrialExpiry,
  trialDetails?: DbOrganizationTrialDetails
): ReactNode => {
  if (trialExpiry.relativeTime && trialDetails?.remainingVerifications) {
    return (
      <Text variant={TextVariant.sm} className="text-center">
        You have <StyledValue>{trialExpiry.relativeTime}</StyledValue> to use
        the remaining
        <br />
        <StyledValue>{trialDetails.remainingVerifications}</StyledValue>{" "}
        verifications in your boring trial
      </Text>
    );
  } else {
    return (
      <Text variant={TextVariant.sm} className="text-center">
        {!trialExpiry.relativeTime
          ? "Your boring trial has expired"
          : "You have used all your trial verifications"}
      </Text>
    );
  }
};

const Dashboard: FC = () => {
  const signedInUser = useRecoilValue(signedInUserAtom);
  const [isProcessingWebhook, setIsProcessingWebhook] = useState(false);
  const [showSetupWebhookButton, setShowSetupWebhookButton] = useState(true);
  const [webhookError, setWebhookError] = useState("");
  const [isTrialDialogOpen, setIsTrialDialogOpen] = useState(true);

  const {
    organizationId,
    isProspectWebhookCreated,
    trialDetails,
    isTrial,
    isPayingCustomer,
    isSegmentedAccount,
  } = signedInUser || {};

  const trialExpiry = useMemo(
    () => calculateTrialExpiry(trialDetails),
    [trialDetails]
  );

  const handleSetupWebhook = useCallback(async () => {
    setIsProcessingWebhook(true);
    try {
      const result = await setupProspectWebhook();
      if (result.status === 200) {
        setShowSetupWebhookButton(false);
      }
    } catch (error) {
      setWebhookError(
        `Error setting up webhook (Code ${Errors.CONNECT_OUTREACH_SET_WEBHOOK_FUNCTION_ERROR}).`
      );
    } finally {
      setIsProcessingWebhook(false);
    }
  }, []);

  const handleUpgrade = useCallback(() => {
    setIsTrialDialogOpen(false);
    window.open(scheduleMeetingUrl);
  }, []);

  useEffect(() => {
    if (isProspectWebhookCreated) {
      setShowSetupWebhookButton(false);
    }
  }, [isProspectWebhookCreated]);

  const renderWebhookSetup = () => (
    <Animation className="col-12">
      <div className="d-flex h-100 align-items-center justify-content-center m-auto">
        <div className="w-75 w-lg-400px">
          <Text variant={TextVariant.h4} className="mb-3 text-center">
            Connect to your Outreach account
          </Text>
          <div className="mb-3">
            <Button
              fullWidth
              variant={ButtonVariants.brand}
              type="submit"
              disabled={isProcessingWebhook}
              onClick={handleSetupWebhook}
              loading={isProcessingWebhook}
              startIcon={<i className="fa-solid fa-plug" />}
            >
              Setup webhook
            </Button>
          </div>
          <Text variant={TextVariant.sm} className="text-center">
            I will continue later,{" "}
            <span onClick={signUserOut} className="btn-link cursor-pointer">
              sign out
            </span>{" "}
            for now.
          </Text>
          <FormHelper helperText={webhookError} severity={Severity.error} />
        </div>
      </div>
    </Animation>
  );

  const renderDialog = (): ReactNode => (
    <Dialog
      open={isTrialDialogOpen}
      primaryButtonProps={{
        onClick: handleUpgrade,
        children: "Upgrade",
        variant: ButtonVariants.brand,
      }}
      onClose={() => setIsTrialDialogOpen(false)}
    >
      {getTrialMessage(trialExpiry, trialDetails)}
    </Dialog>
  );

  return showSetupWebhookButton ? (
    renderWebhookSetup()
  ) : (
    <div className="p-0">
      <Tabs
        options={[
          {
            label: "Insights",
            value: "insights",
            children: (
              <Insights
                organizationId={organizationId}
              />
            ),
          },
          ...(isSegmentedAccount
            ? [
                {
                  label: "Users",
                  value: "users",
                  children: <Users />,
                },
              ]
            : []),
          {
            label: "Settings",
            value: "settings",
            children: <Settings />,
          },
        ]}
      />
      {!isPayingCustomer && isTrial && renderDialog()}
    </div>
  );
};

export default Dashboard;
