import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import useLocalStorage from "../../common/hooks/useLocalStorage";
import {
  getOutreachAuthUrl,
  reload,
  validateOutreachAuthCode,
} from "../../services/firebase";
import { signedInUserAtom } from "../../state/atoms";
import { Errors } from "../../types/errors";
import { LOCAL_STORAGE_KEYS } from "../consts";

export const useConnectOutreach = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");

  const [outreachAuthCode, setOutreachAuthCode] = useLocalStorage(
    LOCAL_STORAGE_KEYS.outreachAuthCode,
    ""
  );
  const signedInUser = useRecoilValue(signedInUserAtom);

  useEffect(() => {
    const getConnectOutreachUrl = async () => {
      try {
        setIsProcessing(true);
        setError("");

        const { result, status } = await getOutreachAuthUrl();

        if (status === 200 && result?.outreachAuthUrl) {
          setUrl(result.outreachAuthUrl);
        } else {
          setError(
            `Error connecting to Outreach (Code: ${Errors.CONNECT_OUTREACH_GENERATE_URL_FUNCTION_ERROR}). Please contact us for help.`
          );
        }
      } catch (error) {
        setError(
          `Error connecting to Outreach (Code: ${Errors.CONNECT_OUTREACH_GENERATE_URL_FUNCTION_FAILURE}). Please contact us for help.`
        );
      } finally {
        setIsProcessing(false);
      }
    };

    getConnectOutreachUrl();
  }, []);

  useEffect(() => {
    const updateOutreachAccessToken = async () => {
      try {
        setIsProcessing(true);
        setError("");

        if (signedInUser && outreachAuthCode) {
          const { status, result } =
            await validateOutreachAuthCode(outreachAuthCode);
          setOutreachAuthCode("");
          if (status === 200 && result?.isOutreachAuthorized) {
            reload();
          } else {
            setError(
              `Error connecting to Outreach (Code: ${Errors.CONNECT_OUTREACH_UPDATE_ORG_FUNCTION_ERROR}). Please contact us for help.`
            );
          }
        }
      } catch (error) {
        setError(
          `Error connecting to Outreach (Code: ${Errors.CONNECT_OUTREACH_UPDATE_ORG_FUNCTION_FAILURE}). Please contact us for help.`
        );
      } finally {
        setIsProcessing(false);
      }
    };

    updateOutreachAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outreachAuthCode, signedInUser]);

  const openOutreachAuthUrl = () => {
    if (url) {
      const width = 800; // Set the width of the popup
      const height = 800; // Set the height of the popup

      // Calculate the position to center the window
      const left = (window.innerWidth - width) / 2 + window.screenX;
      const top = (window.innerHeight - height) / 2 + window.screenY;

      const params = `width=${width},height=${height},scrollbars=yes,resizable=yes,top=${top},left=${left}`;

      const newWindow = window.open(url, "Authorize Outreach", params);
      newWindow?.focus();
    }
  };

  return {
    isProcessing,
    url,
    error,
    openOutreachAuthUrl,
  };
};
