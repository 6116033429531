import React, { FC, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Helmet } from "react-helmet-async";

import { resetPassword } from "../../services/firebase";
import { resetPasswordFormAtom } from "../../state/atoms";
import { canSubmitResetpasswordFormSelector } from "../../state/selectors";
import { Errors } from "../../types/errors";
import mainImage from "../../assets/images/main-image.svg";
import TermsFooter from "./TermsFooter";
import Card from "../../components/Display/Card";
import Button, { ButtonVariants } from "../../components/Display/Button";
import Input from "../../components/DataInput/Input";
import FormHelper, { Severity } from "../../components/DataInput/FormHelper";
import Text, { TextVariant } from "../../components/Display/Text";
import Link from "../../components/Display/Link";
import Animation from "../../components/Display/Animation";

const ResetPassword: FC = () => {
  const [resetPasswordForm, setResetPasswordForm] = useRecoilState(
    resetPasswordFormAtom
  );
  const canSubmitResetPasswordForm = useRecoilValue(
    canSubmitResetpasswordFormSelector
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (canSubmitResetPasswordForm) {
      try {
        setIsProcessing(true);
        setError("");
        await resetPassword(resetPasswordForm.email);
        setIsEmailSent(true);
      } catch (error) {
        setError(
          `Error Resetting password (Code ${Errors.AUTH_RESET_PASSWORD_FAILURE}). Please validate the data you have entered, and try again.`
        );
      } finally {
        setIsProcessing(false);
      }
    }
  };

  return (
    <Card>
      <Animation>
        <Helmet>
          <title>Reset password | boring</title>
        </Helmet>
        <div className="d-flex flex-column h-100 align-items-center justify-content-center m-auto">
          <form className="w-75 w-lg-400px" onSubmit={onSubmit}>
            <div className="d-flex w-100 align-items-center justify-content-center">
              <img src={mainImage} className="img-fluid" alt="Boring plugins" />
            </div>
            <Text variant={TextVariant.h4} className="mb-2">
              Reset password
            </Text>
            <div className="d-flex flex-column gap-3 mb-3">
              {isEmailSent ? (
                <Text>
                  We sent a reset password email to {resetPasswordForm.email}.
                  Please click the reset password link to set your new password.
                </Text>
              ) : (
                <div>
                  <Input
                    label="Email"
                    type="email"
                    placeholder="name@example.com"
                    value={resetPasswordForm.email}
                    onChange={(e) =>
                      setResetPasswordForm({
                        ...resetPasswordForm,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Link path="/auth/login">Log in</Link>
              {!isEmailSent && (
                <Button
                  variant={ButtonVariants.brand}
                  type="submit"
                  disabled={!canSubmitResetPasswordForm}
                  loading={isProcessing}
                >
                  Reset password
                </Button>
              )}
            </div>
            <FormHelper helperText={error} severity={Severity.error} />
            <TermsFooter />
          </form>
        </div>
      </Animation>
    </Card>
  );
};

export default ResetPassword;
