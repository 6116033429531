import { atom } from "recoil";
import {
  SignupForm,
  LoginForm,
  ResetPasswordForm,
  BouncePrevention,
  OnboardingSteps,
  OnboardingForm,
} from "../types/forms";
import { AppUser } from "../types/users";
import { Organization } from "../types/organizations";

export const signedInUserAtom = atom<AppUser | null>({
  key: "signedInUserAtom",
  default: null,
});

export const signupFormAtom = atom<SignupForm>({
  key: "signupFormAtom",
  default: {
    name: "",
    organizationName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  },
});

export const onboardingFormAtom = atom<OnboardingForm>({
  key: "onboardingFormAtom",
  default: {
    currentStep: OnboardingSteps.ConfirmOutreachAdmin,
    bouncePrevention: BouncePrevention.AddSequences,
    isOutreachAdmin: false,
  },
});

export const loginFormAtom = atom<LoginForm>({
  key: "loginFormAtom",
  default: {
    email: "",
    password: "",
  },
});

export const resetPasswordFormAtom = atom<ResetPasswordForm>({
  key: "resetPasswordFormAtom",
  default: {
    email: "",
  },
});

export const organizationAtom = atom<Organization | null>({
  key: "organizationAtom",
  default: null,
});

export const extensibilitySdkInitializedAtom = atom<boolean>({
  key: "extensibilitySdkInitializedAtom",
  default: false,
});

export const outreachOrgIdAtom = atom<string | null>({
  key: "outreachOrgIdAtom",
  default: null,
});