import React, { FC } from "react";
import { Grid as MuiGrid } from "@mui/material";
import { GridProps as MuiGridProps } from "@mui/material/Grid";

type GridProps = Omit<MuiGridProps, "sx">;

const Grid: FC<GridProps> = (props) => {
  return <MuiGrid {...props} />;
};

export default Grid;
