import React, { FC, HTMLAttributes } from "react";

export enum AnimationTypes {
  fadeIn = "fade-in",
  fadeOut = "fade-out",
}

interface AnimationProps extends HTMLAttributes<HTMLElement> {
  animationType?: AnimationTypes;
}

const Animation: FC<AnimationProps> = ({
  animationType = AnimationTypes.fadeIn,
  children,
  className,
  ...rest
}) => {
  return (
    <div className={`animation-${animationType} ${className || ""}`} {...rest}>
      {children}
    </div>
  );
};

export default Animation;
