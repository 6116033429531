import React, { FC, useState, useEffect } from "react";
import { styled } from "@mui/system";

import {
  OrganizationStats,
  OutreachEmailStatuses,
  ExtendedEmailStatuses,
} from "../../types/organizations";
import { FONTS } from "../../common/consts";
import { MainChartData } from "./MainChart";
import {
  getOrganizationIdByOutreachOrgId,
  getRangeStats,
  getRangeStatsFromCache,
} from "../../services/firebase";
import {
  getRelativeDate,
  formatLongDate,
  generateDynamicTimeframeOptions,
} from "../../common/utils/date-time";
import Card from "../../components/Display/Card";
import Text, { TextVariant } from "../../components/Display/Text";
import Select from "../../components/DataInput/Select";

import ProspectsTabManager from "./ProspectsTabManager";
import { useParams } from "react-router-dom";
import Link from "../../components/Display/Link";
import dayjs from "dayjs";

export interface TimeframeOption {
  value: string;
  label: string;
  startDate: Date;
  endDate: Date;
}

const timeframeOptions: TimeframeOption[] = [
  {
    value: "this-week",
    label: "This week",
    startDate: getRelativeDate(new Date(), 7),
    endDate: new Date(),
  },
  {
    value: "last-week",
    label: "Last week",
    startDate: getRelativeDate(new Date(), 14),
    endDate: getRelativeDate(new Date(), 7),
  },
  {
    value: "this-month",
    label: "This month",
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  },
  {
    value: "last-month",
    label: "Last month",
    startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
  },
  ...generateDynamicTimeframeOptions(),
];

const StyledTitle = styled(Text)`
  line-height: 1.43;
  font-size: 1.25rem;
  font-weight: 575;
`;

const StyledSubtitle = styled(Text)`
  font-weight: 300;
  line-height: 1.43;
  letter-spacing: 0.01rem;
`;

const StyledSelect = styled(Select)`
  && {
    font: ${FONTS.sm};
  }
`;

interface Props {
  organizationId?: string;
}

const Insights: FC<Props> = ({ organizationId }) => {
  const { outreachOrganizationId } = useParams<{
    outreachOrganizationId?: string;
  }>();
  const [orgId, setOrgId] = useState<string | undefined>(organizationId);
  const [shouldRefetchStats, setShouldRefetchStats] = useState(true);
  const [selectedTimeframe, setSelectedTimeframe] = useState<TimeframeOption>(
    timeframeOptions[0]
  );
  const [isLoading, setIsLoading] = useState(true);

  const [globalStats, setGlobalStats] = useState<OrganizationStats>({
    prospectsChecked: 0,
    enrichedByBoring: 0,
    bouncesPrevented: 0,
    invalid: 0,
    valid: 0,
    questionable: 0,
    noEmail: 0,
  });

  const [stats, setStats] = useState<MainChartData>(
    Array.from({ length: 7 }, (_, i) => ({
      date: formatLongDate(getRelativeDate(new Date(), 6 - i)),
      prospectsChecked: 0,
      [OutreachEmailStatuses.Valid]: 0,
      [OutreachEmailStatuses.Invalid]: 0,
      [OutreachEmailStatuses.Questionable]: 0,
      [OutreachEmailStatuses.NoEmail]: 0,
      [ExtendedEmailStatuses.EnrichedByBoring]: 0,
      [ExtendedEmailStatuses.BouncesPrevented]: 0,
    }))
  );

  const handleTimeframeChange = (value: TimeframeOption) => {
    setSelectedTimeframe(value);
    setShouldRefetchStats(true);
  };

  useEffect(() => {
    if (organizationId || !outreachOrganizationId) {
      return;
    }

    const fetchOrgId = async (outreachOrganizationId: string) => {
      const response = await getOrganizationIdByOutreachOrgId({
        outreachOrganizationId,
      });

      if (!response?.result?.organizationId) {
        return;
      }

      setOrgId(response.result.organizationId);
    };

    fetchOrgId(outreachOrganizationId);
  }, [outreachOrganizationId, organizationId]);

  useEffect(() => {
    if (orgId && shouldRefetchStats) {
      const getStats = async () => {
        const { startDate, endDate } = selectedTimeframe;
        setIsLoading(true);

        // TODO: adjust timezone since we may get live data
        const rangeStats = await getRangeStatsFromCache({
          organizationId: orgId || "",
          startDate: dayjs(startDate).startOf("day"),
          endDate: dayjs(endDate).endOf("day"),
        });

        const {
          prospectsChecked: prospectsCheckedDb = 0,
          valid: validFromDb = 0,
          invalid: invalidFromDb = 0,
          questionable: questionableFromDb = 0,
          enrichedByBoring: enrichedFromDb = 0,
          bouncesPrevented: bouncesPreventedFromDb = 0,
          noEmail: missingFromDb = 0,
        } = rangeStats.totals || {
          prospectsChecked: 0,
          valid: 0,
          invalid: 0,
          questionable: 0,
          noEmail: 0,
          enrichedByBoring: 0,
          bouncesPrevented: 0,
        };

        const globalStats: OrganizationStats = {
          prospectsChecked: prospectsCheckedDb,
          enrichedByBoring: enrichedFromDb,
          valid: validFromDb,
          invalid: invalidFromDb,
          questionable: questionableFromDb,
          noEmail: missingFromDb,
          bouncesPrevented: bouncesPreventedFromDb,
        };

        setGlobalStats(globalStats);
        delete rangeStats["totals"];

        const stats: MainChartData = Object.keys(rangeStats).map((key) => ({
          date: formatLongDate(new Date(key)),
          prospectsChecked: rangeStats[key].prospectsChecked || 0,
          [OutreachEmailStatuses.Valid]: rangeStats[key].valid || 0,
          [OutreachEmailStatuses.Invalid]: rangeStats[key].invalid || 0,
          [OutreachEmailStatuses.Questionable]:
            rangeStats[key].questionable || 0,
          [OutreachEmailStatuses.NoEmail]: rangeStats[key].noEmail || 0,
          [ExtendedEmailStatuses.EnrichedByBoring]:
            rangeStats[key].enrichedByBoring || 0,
          [ExtendedEmailStatuses.BouncesPrevented]:
            rangeStats[key].bouncesPrevented || 0,
        }));

        setStats(stats);
        setShouldRefetchStats(false);
        setIsLoading(false);
      };

      // TODO:: This runs twice on initial load
      getStats();
    }
  }, [orgId, shouldRefetchStats, selectedTimeframe.value]);

  return (
    <div className="d-flex flex-column gap-4">
      <Card className="p-3">
        <div className="d-flex justify-content-between align-items-top">
          <div>
            <StyledTitle variant={TextVariant.xl}>
              Email verification report
            </StyledTitle>
            <StyledSubtitle variant={TextVariant.sm}>
              See how well your email data tool is performing
            </StyledSubtitle>
          </div>
          <div className="d-flex align-items-center">
            <StyledSelect
              value={selectedTimeframe.value}
              options={timeframeOptions}
              onChange={(e) => {
                const { value } = e.target;
                const selectedOption = timeframeOptions.find(
                  (option) => option.value === value
                );
                if (selectedOption) {
                  handleTimeframeChange(selectedOption);
                }
              }}
            />
          </div>
        </div>
      </Card>
      <ProspectsTabManager
        globalStats={globalStats}
        stats={stats}
        isLoading={isLoading}
      />
      {outreachOrganizationId && (
        <div className="d-flex justify-content-center">
          <Link path="/auth/login">Log in as admin</Link>
        </div>
      )}
    </div>
  );
};

export default Insights;
