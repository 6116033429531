import React from "react";
import { Navigate, RouteObject } from "react-router-dom";

import AuthPageContainer from "../components/Layout/AuthPageContainer";
import Signup from "../pages/Auth/Signup";
import Login from "../pages/Auth/Login";
import ResetPassword from "../pages/Auth/ResetPassword";
import Dashboard from "../pages/Dashboard";
import OutreachRedirect from "../pages/Outreach/OutreachRedirect";
import Onboarding from "../pages/Onboarding/Onboarding";
import WaitForOnboarding from "../pages/WaitForOnboarding";
import Insights from "../pages/Insights/Insights";

const nonAuthenticatedRoutes = [
  {
    path: "/auth",
    element: <AuthPageContainer />,
    children: [
      {
        path: "/auth/signup",
        element: <Signup />,
      },
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/view/:outreachOrganizationId",
    element: <Insights />,
  },
  {
    path: "/*",
    element: <Navigate to="/auth/login" replace />,
  },
];

const authenticatedRoutes = [
  {
    path: "/app",
    element: <AuthPageContainer />,
    children: [
      {
        path: "/app/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/app/wait-for-onboarding",
        element: <WaitForOnboarding />,
      },
      {
        path: "/app/continue-onboarding",
        element: <Onboarding />,
      },
      {
        path: "/app",
        element: <Navigate to="/app/dashboard" replace />,
      },
    ],
  },
  {
    path: "/oauth/outreach",
    element: <OutreachRedirect />,
  },
  {
    path: "/*",
    element: <Navigate to="/app/wait-for-onboarding" replace />,
  },
];

export const getRoutes = (signedInUser: boolean): RouteObject[] =>
  signedInUser ? authenticatedRoutes : nonAuthenticatedRoutes;
