import React, { FC, ReactNode } from "react";
import { COLORS, ICON_SIZES } from "../../common/consts";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import Button, { ButtonProps } from "./Button";
import Step from "./Step";

interface StepButton extends Omit<ButtonProps, "onClick"> {
  onClick?: () => void;
}

export interface StepProps {
  component: ReactNode;
  primaryButtonProps?: StepButton;
  secondaryButtonProps?: StepButton;
  backButtonProps?: StepButton;
  footer?: ReactNode;
}

interface StepperProps {
  steps: StepProps[];
  currentStep: number;
  onCurrentStepChange: (newStep: number) => void;
}

const Stepper: FC<StepperProps> = ({
  steps,
  currentStep,
  onCurrentStepChange,
}) => {
  const activeStep = steps[currentStep];
  const {
    component,
    footer,
    primaryButtonProps,
    secondaryButtonProps,
    backButtonProps,
  } = activeStep;

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      onCurrentStepChange(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      onCurrentStepChange(currentStep - 1);
    }
  };

  const handlePrimaryButtonClick = () => {
    if (primaryButtonProps?.onClick) {
      primaryButtonProps.onClick();
    } else {
      handleNextStep();
    }
  };

  const handleSecondaryButtonClick = () => {
    if (secondaryButtonProps?.onClick) {
      secondaryButtonProps.onClick();
    } else {
      handlePreviousStep();
    }
  };

  return (
    <>
      <Step>
        {backButtonProps && (
          <ArrowBack
            width={ICON_SIZES.md}
            height={ICON_SIZES.md}
            onClick={backButtonProps.onClick}
            className="cursor-pointer"
            color={COLORS.backgroundBrandDark}
          />
        )}
        <div className="d-flex flex-column align-items-center justify-content-center gap-5 h-100">
          {component}
          <div className="d-flex gap-3 align-items-center justify-content-center">
            {secondaryButtonProps && (
              <Button
                key={`${currentStep}-secondary-button`}
                className="px-3"
                onClick={handleSecondaryButtonClick}
                disabled={
                  secondaryButtonProps.disabled || secondaryButtonProps.loading
                }
                {...secondaryButtonProps}
              />
            )}
            {primaryButtonProps && (
              <Button
                key={`${currentStep}-primary-button`}
                className="px-3"
                onClick={handlePrimaryButtonClick}
                disabled={
                  primaryButtonProps.disabled || primaryButtonProps.loading
                }
                {...primaryButtonProps}
              />
            )}
          </div>
        </div>
      </Step>
      {footer}
    </>
  );
};

export default Stepper;
