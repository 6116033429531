import React, { FC, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { styled } from "@mui/system";

import { getOutreachUsers } from "../../services/firebase";
import { onboardingFormAtom } from "../../state/atoms";
import { OutreachUser } from "../../types/users";
import { COLORS } from "../../common/consts";
import Text, { TextVariant } from "../../components/Display/Text";
import Select, { SelectOption } from "../../components/DataInput/Select";
import Dialog from "../../components/Display/Dialog";
import Loading from "../../components/Display/Loading";

const MAX_USERS_IN_TRIAL = 3;
const noUsersFoundOption: SelectOption = {
  label: "No users found",
  value: "",
  disabled: true,
};

const StyledValue = styled("span")`
  color: ${COLORS.integrationDisconnected};
`;

const InviteUsers: FC = () => {
  const [onboardingForm, setOnboardingForm] =
    useRecoilState(onboardingFormAtom);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<OutreachUser[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const selectedEmails: string[] = useMemo(
    () => onboardingForm.allowedOwnerEmails || [],
    [onboardingForm.allowedOwnerEmails]
  );

  const handleSelectionChange = (value: string[]) => {
    if (value.length > 3) {
      setIsDialogOpen(true);
      return;
    }
    setOnboardingForm((onboardingForm) => ({
      ...onboardingForm,
      allowedOwnerEmails: value,
    }));
  };

  const options: SelectOption[] = useMemo(
    () =>
      users.length === 0
        ? [noUsersFoundOption]
        : users.map(({ attributes: { email } }) => ({
            label: email,
            value: email,
          })),
    [users]
  );

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);
        const { status, result } = await getOutreachUsers();

        if (status === 200 && result?.users) {
          setUsers(result.users || []);
        }
      } catch (error) {
        console.error("Error fetching users", error);
      } finally {
        setLoading(false);
      }
    };

    getUsers();
  }, []);

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-column gap-3">
        <Text variant={TextVariant.h6} className="text-center">
          Users with access
        </Text>
        <Text variant={TextVariant.sm}>
          Pick who should have boring activated
        </Text>
      </div>
      {loading ? (
        <div className="w-100 py-1 d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <Select
          multiple
          options={options}
          disabled={loading}
          placeholder="Pick users"
          value={selectedEmails}
          onChange={(e) =>
            handleSelectionChange(e.target.value as unknown as string[])
          }
        />
      )}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        You can invite up to{" "}
        <StyledValue>{MAX_USERS_IN_TRIAL} users</StyledValue> during the trial
        period.
        <br />
        Please contact support to upgrade your plan.
      </Dialog>
    </div>
  );
};

export default InviteUsers;
