import { Timestamp } from "firebase/firestore";
import { OrganizationSettings, OrganizationStatus } from "./organizations";

export type UID = string;

export enum ProfileType {
  ADMIN = "ADMIN",
  DEFAULT = "DEFAULT",
  LEADEARSHIP = "LEADEARSHIP",
}

export enum InviteStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  LOCKED = "LOCKED",
}

export type UserSettings = Pick<
  AppUser,
  "firstName" | "title" | "email" | "profile" | "phoneNumber"
>;

export interface AppUser extends OrganizationSettings {
  uid: UID;
  email: string;
  emailVerified?: boolean;
  firstName?: string;
  lastName?: string;
  title?: string;
  phoneNumber?: string;
  organizationId?: string;
  organizationName?: string;
  organizationStatus?: OrganizationStatus;
  isOutreachAuthorized?: boolean;
  isProspectWebhookCreated?: boolean;
  profile?: ProfileType;
  role?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export interface OrgDisplayUser {
  uid?: UID;
  firstName?: string;
  lastName?: string;
  email: string;
  isSelf?: boolean;
  isUserCreated?: boolean;
  inviteStatus?: InviteStatus;
  isDisabled?: boolean;
}

export interface OutreachUser {
  id: number;
  type: string;
  attributes: {
    email: string;
    name: string;
  };
  links: {
    self: string;
  };
}
